import React, { Component } from 'react';
import moment from 'moment';
import 'moment/locale/ja';

import { connect } from 'react-redux';
import { TYPES } from '../../../actions';
import { getListGuessSchedule, setGuessChoice, getGuessChoice, notifyAction, getUserInfo } from '../../../actions';
import { act } from 'react';
import _ from 'lodash';
import Func from '../../../utils/Func';
import { withRouter } from 'react-router-dom';

class Schedule extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userInfo: null,
      currentWeeks: [],
      bookings: {},
      tempBookings: {},
      isMobile: window.innerWidth <= 768,
      salon_uuid: new URLSearchParams(window.location.search).get('sid') ?? '',
      line_id: new URLSearchParams(window.location.search).get('line_user_id') ?? '',
      weekOffset: 0,
      bookingFrames: [
        '10_00',
        '10_30',
        '11_00',
        '11_30',
        '12_00',
        '12_30',
        '13_00',
        '13_30',
        '14_00',
        '14_30',
        '15_00',
        '15_30',
        '16_00',
        '16_30',
        '17_00',
        '17_30',
        '18_00',
        '18_30',
        '19_00',
        '19_30',
        '20_00',
        '20_30',
        '21_00',
      ],
      timeSlots: [],
      scheduleTime: {
        start: '10:00',
        end: '21:00',
      },
    };
  }

  componentDidMount() {
    window.addEventListener('resize', this.handleResize);
    const isMobile = window.innerWidth <= 768;
    const params = {
      startDate: moment().locale('ja').format('YYYY-MM-DD'),
      endDate: moment()
        .locale('ja')
        .add(isMobile ? 6 : 13, 'days')
        .format('YYYY-MM-DD'),
      salon_uuid: this.state.salon_uuid,
    };
    this.setState({
      isMobile,
      currentWeeks: this.getWeeksDates(moment(), isMobile),
      timeSlots: this.generateTimeSlots(),
    });
    // this.props.getBookingFrames({salon_uuid: this.state.salon_uuid});
    this.props.getListGuessSchedule(params);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  UNSAFE_componentWillReceiveProps(props) {
    console.log('props: ', props);
    const { type } = props.actionNotify;
    const { errorCode, message } = Func.getError({ ...props });
    let bookingFrames = [];
    let startTime = '';
    let endTime = '';
    switch (type) {
      case TYPES.ACTION_GET_LIST_SCHEDULE_SUCCESSFULLY:
        const schedules = _.get(props.actionNotify, 'schedules', '{}');
        bookingFrames = _.get(props.actionNotify, 'booking_frames', []);
        startTime = _.get(props.actionNotify, 'start_time', '');
        endTime = _.get(props.actionNotify, 'end_time', '');
        this.setState(
          {
            tempBookings: JSON.parse(schedules),
            bookings: JSON.parse(schedules),
            bookingFrames: JSON.parse(bookingFrames),
            scheduleTime: {
              start: startTime,
              end: endTime,
            },
          },
          () => {
            this.props.getGuessChoice();
            this.setState({
              timeSlots: this.generateTimeSlots(),
            });
          }
        );
        break;
      case TYPES.ACTION_GET_LIST_SCHEDULE_FAIL:
        bookingFrames = _.get(props.actionNotify, 'booking_frames', []);
        startTime = _.get(props.actionNotify, 'start_time', '');
        endTime = _.get(props.actionNotify, 'end_time', '');
        this.setState(
          {
            bookingFrames: JSON.parse(bookingFrames),
            scheduleTime: {
              start: startTime,
              end: endTime,
            },
          },
          () => {
            this.props.getGuessChoice();
            this.setState(
              {
                timeSlots: this.generateTimeSlots(),
              },
              () => {
                this.setAllBlockedBookings();
              }
            );
          }
        );

        break;
      case TYPES.ACTION_GET_GUEST_CHOICE:
        const option = _.get(props, 'actionNotify.guessChoicing.option', {});
        const totalTime = _.get(option, 'total_time', 0);
        const schedulesChoosed = _.get(props, 'actionNotify.guessChoicing.schedules', {});
        const dayStr = moment(schedulesChoosed[0]).format('YYYY-MM-DD');
        const time = moment(schedulesChoosed[0]).format('HH:mm');
        const newBooking = this.adjustSchedule({ ...this.state.bookings }, totalTime);
        this.setState(
          {
            bookings: newBooking,
          },
          () => {
            const newTempBookings = {
              ...this.state.bookings,
            };
            if (dayStr && _.get(newTempBookings, dayStr, false)) {
              newTempBookings[dayStr] = {
                ...this.state.bookings[dayStr],
                [time]: !this.state.bookings[dayStr][time],
              };
            }
            this.setState({
              tempBookings: newTempBookings,
            });
          }
        );

        break;
      case TYPES.ACTION_SET_GUESS_CHOICE:
        if (this.props.history) {
          this.props.history.push(`/user/inputcustomerInfo?sid=${this.state.salon_uuid}${this.state.line_id ? `&line_user_id=${this.state.line_id}` : ''}`);
        } else {
          window.location.href = `/user/inputcustomerInfo?sid=${this.state.salon_uuid}${this.state.line_id ? `&line_user_id=${this.state.line_id}` : ''}`;
        }

        break;
      default:
        break;
    }
    this.props.notifyAction({ type, error_code: errorCode, message });

    if (JSON.stringify(this.state.userInfo) != JSON.stringify(props.userInfo)) {
      this.setState({ userInfo: props.userInfo }, () => {
        if (_.get(this.state, 'userInfo.username')) {
          this.props.getUserInfo();
        }
      });
    }
  }

  adjustSchedule = (schedule, totalTime) => {
    // totalTime is in frames (1 block = 30 minutes)
    console.log('totalTime: ', totalTime);
    let adjustedSchedule = _.cloneDeep(schedule);
    let timeBookingBefore = _.get(this.state, 'userInfo.time_booking_before') || 0;
    let isSalonOwer = _.get(this.state, 'userInfo.role') == 1;
    if (isSalonOwer) {
      timeBookingBefore = 0;
    }
    const currentTime = moment().add(timeBookingBefore, 'hour').format('YYYY-MM-DD HH:mm:ss');

    for (const date in adjustedSchedule) {
      const timeSlots = adjustedSchedule[date];
      for (let i = 0; i < timeSlots.length; i++) {
        const key = Object.keys(timeSlots[i])[0];
        // check if time is in the past
        if (moment(date + ' ' + key).format('YYYY-MM-DD HH:mm:ss') < currentTime) {
          timeSlots[i][key] = false;
          continue;
        }

        for (let j = 1; j <= totalTime; j++) {
          // check if time is out of schedule
          if (i + j < timeSlots.length) {
            const nextKey = Object.keys(timeSlots[i + j])[0];

            if (!_.get(timeSlots[i + j], nextKey, false) && j < totalTime) {
              timeSlots[i][key] = false;
            }
          } else {
            if (j < totalTime) {
              timeSlots[i][key] = false;
            }
            break;
          }
        }
      }
    }

    return adjustedSchedule;
  };

  getWeeksDates = (currentDate, isMobile = false) => {
    const days = [];
    const daysToShow = isMobile ? 7 : 14;

    for (let i = 0; i < daysToShow; i++) {
      days.push(currentDate.clone().add(i, 'days'));
    }
    return days;
  };

  setAllBlockedBookings = () => {
    const dateRange = this.state.currentWeeks.map((day) => day.format('YYYY-MM-DD'));
    const { bookingFrames } = this.state;

    const allBlockBookingsAday = bookingFrames.map((frame) => {
      return {
        [frame.replace('_', ':')]: false,
      };
    });

    const allBlockedBookings = dateRange.reduce((acc, frame) => {
      acc[frame] = allBlockBookingsAday || {};
      return acc;
    }, {});

    this.setState({
      bookings: allBlockedBookings,
      tempBookings: allBlockedBookings,
    });
  };

  handlePreviousWeek = () => {
    const limit = 0;
    if (this.state.weekOffset > limit) {
      const newStartDate = this.state.currentWeeks[0].clone().subtract(7, 'days');
      const params = {
        startDate: newStartDate.format('YYYY-MM-DD'),
        endDate: newStartDate
          .clone()
          .add(this.state.isMobile ? 6 : 13, 'days')
          .format('YYYY-MM-DD'),
        salon_uuid: this.state.salon_uuid,
      };
      this.props.getListGuessSchedule(params);
      this.setState({
        currentWeeks: this.getWeeksDates(newStartDate, this.state.isMobile),
        weekOffset: this.state.weekOffset - 1,
      });
    }
  };

  handleNextWeek = () => {
    const limit = this.state.isMobile ? 3 : 2;
    if (this.state.weekOffset < limit) {
      const newStartDate = this.state.currentWeeks[0].clone().add(7, 'days');
      const params = {
        startDate: newStartDate.format('YYYY-MM-DD'),
        endDate: newStartDate
          .clone()
          .add(this.state.isMobile ? 6 : 13, 'days')
          .format('YYYY-MM-DD'),
        salon_uuid: this.state.salon_uuid,
      };
      this.props.getListGuessSchedule(params);
      this.setState({
        currentWeeks: this.getWeeksDates(newStartDate, this.state.isMobile),
        weekOffset: this.state.weekOffset + 1,
      });
    }
  };

  generateTimeSlots = () => {
    const timeSlots = [];
    const { scheduleTime } = this.state;
    const startTime = moment(_.get(scheduleTime, 'start', '09:00'), 'HH:mm');
    const endTime = moment(_.get(scheduleTime, 'end', '21:00'), 'HH:mm');

    while (startTime.isSameOrBefore(endTime)) {
      timeSlots.push(startTime.format('HH:mm'));
      startTime.add(30, 'minutes');
    }

    return timeSlots;
  };

  toggleBooking = (time, dayStr) => {
    this.setState((prevState) => {
      const schedules = [`${dayStr} ${time}:00`];
      this.props.setGuessChoice({
        schedules: [...schedules],
      });
      const newTempBookings = {
        ...prevState.bookings,
        [dayStr]: {
          ...prevState.bookings[dayStr],
          [time]: false,
        },
      };
      return { tempBookings: newTempBookings };
    });
  };

  handleResize = () => {
    const isMobile = window.innerWidth <= 768;
    if (this.state.isMobile !== isMobile) {
      this.setState({
        isMobile,
        currentWeeks: this.getWeeksDates(moment(), isMobile),
        weekOffset: 0,
      });
    }
  };

  render() {
    const { bookings, tempBookings, currentWeeks, isMobile, weekOffset, timeSlots } = this.state;
    console.log('time slot', timeSlots);
    const prevButtonDisabled = weekOffset <= 0;
    const nextButtonDisabled = weekOffset >= (isMobile ? 3 : 2);
    return (
      <div className="schedule text-dark w-100">
        <h5 className="m-0 " style={{ fontSize: '1.1em', letterSpacing: '2.5px' }}>
          サロンの空き状況
        </h5>

        <div className="navigation d-flex flex-column font-weight-bold w-100">
          <div className="w-100 date-week d-flex flex-row justify-content-between">
            <button
              className={`btn-week prev-week w-10 font-weight-bold ${isMobile ? 'position-absolute' : ''} ${prevButtonDisabled ? 'disabled' : ''}`}
              onClick={this.handlePreviousWeek}
              disabled={prevButtonDisabled}
            >
              <i className="fa fa-chevron-left"></i> 前の一週間
            </button>

            {isMobile && (
              <div
                className="w-10 font-weight-bold d-flex align-items-center justify-content-center"
                style={{ maxHeight: 112, height: 'auto', minWidth: 80, border: '2px solid #c1c1c1', borderBottom: 'none' }}
              >
                日時
              </div>
            )}

            <div className="w-100 d-flex flex-column">
              <div
                style={{ minHeight: 35, fontSize: '16px', border: '2px solid #c1c1c1', borderLeft: 'none', borderBottom: 'none' }}
                className="month-year w-100 h-50 d-flex text-center justify-content-center"
              >
                {currentWeeks.length > 0 && currentWeeks[0].locale('ja').format('YYYY[年]MM[月]')}
              </div>
              <table className="h-100 ">
                <thead>
                  <tr className="text-center ">
                    {currentWeeks.map((day) => (
                      <th
                        key={day.format('YYYY-MM-DD')}
                        className={`text-center ${day.isSame(moment(), 'day') ? 'today' : ''} ${day.day() === 6 ? 'saturday' : ''} ${day.day() === 0 ? 'sunday' : ''}`}
                      >
                        {` ${day.format('D')} (${day.format('dd')})`}
                      </th>
                    ))}
                  </tr>
                </thead>
              </table>
            </div>
            <button
              className={`btn-week next-week w-10 font-weight-bold ${isMobile ? 'position-absolute' : ''} ${nextButtonDisabled ? 'disabled' : ''}`}
              onClick={this.handleNextWeek}
              disabled={nextButtonDisabled}
            >
              次の一週間 <i className="fa fa-chevron-right"></i>
            </button>
            <div className="sp-txt w-100 d-flex justify-content-end align-items-center py-10">
              <p className="m-0">◎：予約可能 &nbsp;&nbsp;&nbsp;&nbsp; ×：予約不可能</p>
            </div>
          </div>
          {/* {isMobile && (
                        <div className='direction-pm text-dark w-100 d-flex flex-row justify-content-center my-20'>
                            <a className='mr-30 d-flex' href='#time-break-1'><i className="fa-solid fa-sort-down mr-10" style={{ marginTop: "3px" }}></i><span>午後(12:00-18:00)</span></a>
                            <a className=' d-flex' href='#time-break-2'><i className="fa-solid fa-sort-down mr-10" style={{ marginTop: "3px" }}></i><span>夜(18:00-)</span> </a>
                        </div>
                    )} */}
          <div className="schedule-select w-100 d-flex flex-row" style={{ borderTop: 'none' }}>
            <div style={{ minHeight: 20 }} className="time-day time-day-left d-flex flex-column w-10">
              {timeSlots.map((time, index) => {
                const id = time === '12:00' ? 'time-break-1' : time === '18:00' ? 'time-break-2' : `time-slot_${index}`;

                return (
                  <div key={time} id={id} className={`time-slot time-slot_${index}`}>
                    {time}
                  </div>
                );
              })}
            </div>
            <div className="table-data w-100">
              <table className="h-100">
                <tbody>
                  {timeSlots.map((time) => (
                    <tr key={time}>
                      {currentWeeks.map((day) => {
                        const dayStr = day.format('YYYY-MM-DD');
                        const isBooked = bookings[dayStr] && bookings[dayStr].find((el) => el[time.replace('_', ':')] === false);
                        const isTempBooked = tempBookings[dayStr] && tempBookings[dayStr][time.replace('_', ':')];

                        return (
                          <td
                            key={dayStr}
                            className={isBooked ? 'booked' : isTempBooked ? 'temp-booked' : 'available'}
                            onClick={() => !isBooked && this.toggleBooking(time, dayStr)}
                            style={{ color: isBooked ? '#777878' : isTempBooked ? '#ec1313' : '#ec1313' }}
                          >
                            {isBooked ? '×' : !isTempBooked ? '◎' : '◎'}
                          </td>
                        );
                      })}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div style={{ minHeight: 20 }} className={`time-day time-day-right flex-column w-10 ${isMobile ? 'd-none' : 'd-flex'}`}>
              {timeSlots.map((time) => (
                <div key={time} className="time-slot">
                  {time}
                </div>
              ))}
            </div>
          </div>

          {isMobile && (
            <div className="w-100 d-flex justify-content-between mt-20">
              <button
                className={`btn-week btn-week-bottom prev-week prev-week-bottom w-10 font-weight-bold ${prevButtonDisabled ? 'disabled' : ''}`}
                onClick={this.handlePreviousWeek}
                disabled={prevButtonDisabled}
              >
                <i className="fa fa-chevron-left mr-10"></i> 前の一週間
              </button>
              <button
                className={`btn-week btn-week-bottom next-week next-week-bottom w-10 font-weight-bold ${nextButtonDisabled ? 'disabled' : ''}`}
                onClick={this.handleNextWeek}
                disabled={nextButtonDisabled}
              >
                次の一週間 <i className="fa fa-chevron-right ml-10"></i>
              </button>
            </div>
          )}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { staffReducer, userReducer } = state;
  return {
    actionNotify: staffReducer.actionNotify,
    guessChoicing: staffReducer.guessChoicing,
    userInfo: userReducer.userInfo,
  };
}

export default withRouter(
  connect(mapStateToProps, {
    getListGuessSchedule,
    setGuessChoice,
    getGuessChoice,
    notifyAction,
    getUserInfo,
  })(Schedule)
);
