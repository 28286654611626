import React, { Component } from 'react';
import Func from '../../../utils/Func';
import _ from 'lodash';

export default class ShiftTable extends Component {
    state = {
        selectedCells: [],
    }
    isSelecting = false; 

    handleMouseDown = (e, item, index) => {
        e.preventDefault();
        this.isSelecting = true;
        this.setState({ selectedCells: [{index, item}] }); // Start selection with the clicked cell
      };
    
    handleMouseOver = (e, item, index) => {
        e.preventDefault();
        console.log("handleMouseOver");
        if (this.isSelecting) {
            this.setState((prevState) => {
                if (!this.checkHasIndex(index)) {
                    return { selectedCells: [...prevState.selectedCells, {index, item}] };
                }
                return prevState;
            });
        }
        e.stopPropagation();
    };

    handleTouchMove = (e) => {
        e.preventDefault();
        console.log("handleTouchMove");
        var touch = e.touches[0];
        var el = document.elementFromPoint(touch.pageX  - window.pageXOffset , touch.pageY - window.pageYOffset);
        if(!el || !el.getAttribute('data-item') || !el.getAttribute('data-index')) return
        console.log("el: ", el);
        const index = parseInt(el.getAttribute('data-index'));
        const item = JSON.parse(el.getAttribute('data-item'));
        console.log("index: ", index);
        if (this.isSelecting) {
            this.setState((prevState) => {
                if (!this.checkHasIndex(index)) {
                    return { selectedCells: [...prevState.selectedCells, {index, item}] };
                }
                return prevState;
            });
        }
    };

    checkHasIndex = (index) => {
        const { selectedCells } = this.state;
        return selectedCells.find(x => {
            return x.index === index
        });
    }

    handleMouseUp = (e) => {
        e.preventDefault();
        
        if(!this.isSelecting) return
        this.isSelecting = false;
        const { selectedCells } = this.state;
        if (selectedCells.length === 0) return
        let firstElBookingState = _.get(selectedCells[0], 'item.state.booking_state');
        if (!firstElBookingState) return
        firstElBookingState = firstElBookingState === Func.BookingStates.close ? Func.BookingStates.open : Func.BookingStates.close
        console.log("firstElBookingState: ", firstElBookingState);
        const reversedStateSelectedCells = selectedCells.forEach(x => {
            const bookingState = x.item.state.booking_state;
            const newBookingState = firstElBookingState
            const item = x.item;
            const index = x.index + this.props.startIndex;
            if(bookingState !== newBookingState) {
                this.props.handleClickCell(item, index);
            }
            // return {
            //   ...x,
            //   item: {
            //     ...x.item,
            //     ...x.index,
            //     state: {
            //       ...x.item.state,
            //       booking_state: newBookingState
            //     }
            //   }
            // };
          });
        // reversedStateSelectedCells.forEach(x => {
        //     const item = x.item;
        //     const index = x.index + this.props.startIndex;
        //     this.props.handleClickCell(item, index);
        // })
        this.setState({ selectedCells: [] });
    };
    
    render() {
        const { data, id, startIndex, handleClickCell, paddingVertical } = this.props;
        console.log("data: ", data);
        // console.log("this.props: ", this.props);
        const padding = paddingVertical ? {paddingTop: paddingVertical, paddingBottom: paddingVertical} : {};
        const backgroundColor = {[Func.BookingSlotsStates.blank]: "white", 
            [Func.BookingSlotsStates.hasBooking]: "#fffedf", 
            [Func.BookingSlotsStates.full]: "#ffd9d9"
        }
        // const startIndex = id === "second-table" ? 11 : 0;
        return (
            <div className='container-fluid w-100 d-flex justify-content-center text-center mt-20'>
                <table className="table table-bordered table-case table-shift-pc">
                    <tbody className='text-dark font-weight-bold'>
                        {data.map((item, index) => (
                            <tr key={index}>
                                <td
                                    style={{width: "40%", ...padding}}
                                >{item.time}</td>
                                <td 
                                    data-index = {index + startIndex}
                                    data-item = {JSON.stringify(item)}
                                    className='multi-select'
                                    style={{
                                        userSelect: 'none',
                                        touchAction: 'none',
                                        cursor:'pointer', 
                                        backgroundColor: backgroundColor[item.state.booking_slots_state],
                                        ...padding
                                    }}
                                    // onClick={() => handleClickCell(item, startIndex + index)}
                                    onMouseDown={(e) => this.handleMouseDown(e, item, index)}
                                    onTouchStart={(e) => this.handleMouseDown(e, item, index)}
                                    onMouseOver={(e) => this.handleMouseOver(e, item, index)}
                                    onTouchMove={(e) => this.handleTouchMove(e)}
                                    onMouseUp={(e) => this.handleMouseUp(e)}
                                    onTouchEnd={(e) => this.handleMouseUp(e)}
                                >
                                    {item.state.booking_state === Func.BookingStates.close ? "×" : ""}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        );
    }
}
