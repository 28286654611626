import React, { Component } from 'react';
import { connect } from 'react-redux';
import CalenderForm from '../../components/calender/CalenderForm';
import ShiftTable from './ShiftTable';
import moment from 'moment/moment';
import { staffReducer } from '../../../reducers/staffReducer';
import { getShift, saveShift, notifyAction } from '../../../actions';
import Func from '../../../utils/Func';
import { data, error } from 'jquery';
import { TYPES } from '../../../actions';
import _ from 'lodash';
import { LANGUAGES, getTranslate, notify } from '../../../utils';

class ShiftManagement extends Component {
    constructor(props) {
        super(props);
        this.state = {
            date: new Date(),
            data: [],
            inputValue: '',
            startTime: '',
            endTime: '',
            timeRange: '',
            error: '',
            shiftDetail: '{}',
            isMobile: window.innerWidth <= 768,
            paddingVertical: 0.01,
        };
        this.inputRef = React.createRef();
    }

    componentDidMount() {
        this.loadDataForDate(this.state.date);
        window.addEventListener('resize', this.handleResize);
    }
    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize);
    }
    
    handleResize = () => {
        this.setState({ isMobile: window.innerWidth <= 767 }, () => {
            this.handleTableContainerSize();
        });
    }

    handleTableContainerSize = () => {
        this.setState({ paddingVertical: 0.01 }, () => {
            const windowHeight = window.innerHeight;
            const height = document.getElementById('shift-management-container').offsetHeight;
            let paddingVertical = this.state.data.length === 0 ? 0 :((windowHeight - height)/(2 * this.state.data.length));
            paddingVertical = paddingVertical > 0 ? paddingVertical : 0.01;
            this.setState({ paddingVertical });
        });
        
    }

    UNSAFE_componentWillReceiveProps(props) {
        console.log("UNSAFE_componentWillReceiveProps props: ", props);
        const { type } = props.actionNotify;
        const { errorCode, message } = Func.getError({ ...props })
        let shiftDetail = "{}";
        let timeRange = "";
        switch (type) {
            case TYPES.ACTION_GET_SHIFT_SUCCESSFULLY:
                shiftDetail = _.get(props.actionNotify, 'shiftDetail', "{}");
                shiftDetail = Func.formatScheduleToDisplay(shiftDetail);
                this.setTimeRange(shiftDetail);
                this.setState({ data: shiftDetail, error: '' }, () => {
                    this.handleResize();
                });
                break;
            case TYPES.ACTION_GET_SHIFT_FAIL:
                shiftDetail = _.get(props.actionNotify, 'shiftDetail', "{}");
                shiftDetail = Func.formatScheduleToDisplay(shiftDetail);
                this.setTimeRange(shiftDetail);
                this.setState({ data: shiftDetail, error: '' },
                    () => {
                        if(message === "not found schedule") {
                            this.updateData();
                        }else{
                            this.handleResize();
                        }
                    }
                );
                if(message === "not found schedule") {
                    return
                }
                break;
            case TYPES.ACTION_SAVE_SHIFT_SUCCESSFULLY:
                this.loadDataForDate(this.state.date);
                break;
            case TYPES.ACTION_SAVE_SHIFT_FAIL:
                this.loadDataForDate(this.state.date);
                break;
            default:
                break;
        }
        this.props.notifyAction({ type, error_code: errorCode, message });
    }

    setTimeRange = (shiftDetail) => {
        const startTime = _.get(shiftDetail, '0[time]', '');
        const endTime = _.get(shiftDetail, `${shiftDetail.length - 1}[time]`, '');
    
        const formatTime = (time) => {
            const hour = moment(time, "HH:mm").hour();
            return hour < 10 ? moment(time, "HH:mm").format("h[h]mm") : moment(time, "HH:mm").format("HH[h]mm");
        };
    
        const timeRange = `${formatTime(startTime)}-${formatTime(endTime)}`;
    
        this.setState({ startTime: startTime, endTime: endTime, timeRange: timeRange });
    }

    handleSetDateChild = (newDate) => {
        this.setState({ date: newDate }, () => {
            this.loadDataForDate(newDate);
        });
    }

    handleInputChange = (index, event) => {
        let value = parseInt(event.target.value);
        if (value < 0) value = 0;
        const newData = [...this.state.data];
        newData[index].state = value;
        this.setState({ data: newData });
    }

    handleClickCell = (item, index) => {
        console.log("handleClickCell item: ", item);
        console.log("handleClickCell index: ", index);
        const newData = [...this.state.data];
        console.log("newData: ", newData);
        const oldBookingState = newData[index].state.booking_state;
        const newBookingState = oldBookingState === Func.BookingStates.close ? Func.BookingStates.open : Func.BookingStates.close;
        newData[index].state.booking_state = newBookingState
        this.setState({ data: newData });
    }

    handleBulkInputChange = (event) => {
        let value = parseInt(event.target.value);
        if (value < 0) value = 0;
        this.setState({ inputValue: value });
    }

    isNotTimeRangeFormat = (input) => {
        try {
            const times = input.split('-');
    
            if (times.length !== 2) {
                return true;
            }
    
            const format1 = 'H[h]mm';
            const format2 = 'HH[h]mm';
    
            const isValidFirstTime = moment(times[0], [format1, format2], true).isValid();
            const isValidSecondTime = moment(times[1], [format1, format2], true).isValid();
    
            if (!(isValidFirstTime && isValidSecondTime)) {
                return true;
            }
    
            // Additional check to ensure minutes are either "00" or "30"
            const validMinutes = ['00', '30'];
            const firstTimeMinutes = moment(times[0], [format1, format2]).format('mm');
            const secondTimeMinutes = moment(times[1], [format1, format2]).format('mm');
    
            if (!validMinutes.includes(firstTimeMinutes) || !validMinutes.includes(secondTimeMinutes)) {
                return true;
            }
    
            return false;
    
        } catch (err) {
            return true;
        }
    }

    isTimeRangeOutOfRange = () => {
        try{
            let { startTime, endTime, timeRange } = this.state;
            startTime = moment(startTime, "HH:mm");
            endTime = moment(endTime, "HH:mm");

            const times = timeRange.split('-');
            const startTimeSplit = moment(times[0], "HH[h]mm");
            const endTimeSplit = moment(times[1], "HH[h]mm");

            if (startTimeSplit.isBefore(startTime) || endTimeSplit.isAfter(endTime)) {
                return true;
            }

            if (startTimeSplit.isAfter(endTimeSplit)) {
                return true;
            }

            return false;
        }catch(err) {
            return true;
        }
        
    }

    handleTimeRangeChange = (event) => {
        const value = event.target.value;

        this.setState({ timeRange: value });
    }

    applyBulkChange = () => {
        const { inputValue, timeRange } = this.state;
        let isOk = true;
        if (isNaN(parseInt(inputValue))) {
            notify(getTranslate(LANGUAGES.TEXT_PLEASE_ENTER_THE_NUMBER_OF_SLOTS), 'error');
            isOk = false;
        }
        
        if (this.isNotTimeRangeFormat(timeRange)) {
            notify(getTranslate(LANGUAGES.TEXT_PLEASE_ENTER_TIME_RANGE_WITH_FORMAT_FIRST_TIME_SLOT_LAST_TIME_SLOT__EX__9H00_12H00
            ), 'error');
            isOk = false;
        }

        if (this.isTimeRangeOutOfRange()) {
            notify(getTranslate(LANGUAGES.TEXT_THE_TIME_RANGE_YOU_SPECIFIED_IS_OUTSIDE_SALON_WORKING_TIME), 'error');
            isOk = false;
        }

        if (!isOk) return;

        const times = timeRange.split('-');
        const startTime = moment(times[0], "HH[h]mm");
        const endTime = moment(times[1], "HH[h]mm");
        
        const newData = this.state.data.map(item => {
            let { time } = item;
            time = moment(time, "HH:mm");
            if(time.isBetween(startTime, endTime, null, '[]')) {
                return { ...item, state: inputValue };
            }else{
                return item;
            }

        });
        this.setState({ data: newData, inputValue: '' });
    }

    loadDataForDate = (date) => {
        this.props.getShift(moment(date).format('YYYY-MM-DD'));
    }


    updateData = () => {
        const { date, data } = this.state;
        const dataToSave = Func.formatScheduleToSave(date, data);
        this.props.saveShift(dataToSave);

    }

    render() {
        const { data } = this.state;
        const dataLength = data.length || 0;
        const firstTableData = data.slice(0, Math.round(dataLength / 2));
        const secondTableData = data.slice(Math.round(dataLength / 2), dataLength);
        return (
            <div className='container-fluid shift-management-container' id="shift-management-container">
                <div className='text-dark d-flex flex-column align-items-center'>
                    <CalenderForm handleSetDateChild={this.handleSetDateChild} />
                    <div className='table-shift container-fluid'>

                        {!this.state.isMobile && <div className='row d-flex justify-content-center'><div className='col-6 p-0' style={{ maxWidth: "400px" }}>
                            <ShiftTable 
                                startIndex={0}
                                id="first-table" 
                                data={firstTableData} 
                                handleClickCell={this.handleClickCell} 
                            />
                        </div>
                            <div className='col-6 p-0' style={{ maxWidth: "400px" }}>
                                <ShiftTable 
                                    startIndex={Math.round(dataLength / 2)}
                                    id="second-table" 
                                    data={secondTableData} 
                                    handleClickCell={this.handleClickCell}
                                />
                            </div></div>}

                        {this.state.isMobile && <div className='row d-flex justify-content-between align-items-center'><div className='col-12 p-0'>
                            <ShiftTable 
                                startIndex={0} 
                                id="first-table" 
                                data={data} 
                                handleClickCell={this.handleClickCell} 
                                paddingVertical={this.state.paddingVertical} 
                                />
                        </div>
                        </div>}


                    </div>
                </div>
                {/* <div className='text-dark d-flex flex-column align-items-center w-100 mt-3'>
                    <div  style={{maxWidth:700}} className='select-all-slot w-100 border border-dark p-4 p-md-3 p-sm-2 pt-2' >
                        <label className='title-case text-dark text-center mt-0  font-weight-bold' style={{ fontSize: "22px", width: '100%', textAlign: 'center' }}>枠の一括設定</label>
                        <div className="d-flex flex-column ml-md-3 mr-md-3 m-md-1 pt-1">
                            <div className="d-flex align-items-center">
                                <label
                                    style={{ 
                                        height: "fit-content", 
                                        minWidth: 80, 
                                        width: "15%",
                                        fontSize: "1.1rem" }}
                                    className="text-dark m-0 font-weight-bold mr-2 "
                                    htmlFor="set-time-range"
                                >
                                    時間範囲
                                </label>
                                <input
                                    ref={this.inputRef}
                                    style={{
                                        // width: 250,
                                        height: 50,
                                        textAlign: "left",
                                        fontWeight: 700,
                                        padding: "0",
                                        width: "85%",
                                    }}
                                    className="form-control border border-dark pl-3 font-weight-bold"
                                    type="text"
                                    id="set-time-range"
                                    value={this.state.timeRange}
                                    onChange={this.handleTimeRangeChange}
                                />
                            </div>
                            <div className="d-flex align-items-center">
                                <div style={{minWidth: 80, width: "15%"}}></div>
                                <small
                                    className="pl-md-3 pl-3"
                                    style={{
                                        width: 250,
                                        marginTop: '2px', // Adjust as needed
                                        marginLeft: 'auto', // Adjust this value to align with the input field
                                        width: '85%',
                                    }}
                                >
                                    ※最初の時間帯~最後の時間帯のフォーマットで入力してください (例: 9h00-12h00)
                                </small>
                            </div>
                            
                            
                        </div>
                        <div className="d-flex justify-content-between align-items-center ml-md-3 mr-md-3 pt-3">
                            <div 
                                className="d-flex align-items-center pr-3"
                                style={{
                                    width: "70%",
                                }}
                                >
                                <label 
                                    htmlFor="bulk-slot" 
                                    className="text-dark m-0 font-weight-bold mr-2"
                                    style={{ 
                                        height: "fit-content", 
                                        minWidth: 80, 
                                        width: "22%", 
                                        fontSize: "1.1rem" }}
                                >枠数</label>
                                <input
                                    type="number"
                                    id="bulk-slot"
                                    className="form-control border border-dark pl-3"
                                    style={{
                                        // width: 250,
                                        height: 50,
                                        textAlign: "left",
                                        fontWeight: 700,
                                        padding: "0",
                                        width: "70%",
                                    }}
                                    value={ this.state.inputValue }
                                    onChange={this.handleBulkInputChange}
                                />
                            </div>
                            <button
                                className="btn btn-success"
                                style={{
                                    width: 250,
                                    minWidth: 80,
                                    height: 50,
                                    textAlign: "center",
                                    fontWeight: 700,
                                    fontSize: "16px",
                                    padding: "0",
                                    width: "30%",
                                }}
                                onClick={this.applyBulkChange}
                            >
                                一括設定
                            </button>
                        </div>

                    </div>
                </div> */}


                {/* Update Button */}
                <div className="d-flex justify-content-center pt-2">
                    <button
                        className="btn btn-success"
                        style={{ minWidth: '200px', minHeight: '50px', fontSize: '22px', fontWeight: 'bold' }}
                        onClick={() => this.updateData()}
                    >
                        更新
                    </button>
                </div>

            </div>
        );
    }
}

function mapStateToProps(state) {
    const { staffReducer } = state;

    return {
        actionNotify: staffReducer.actionNotify
    };
}

export default connect(mapStateToProps, {
    getShift,
    saveShift,
    notifyAction
})(ShiftManagement);