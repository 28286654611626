/* eslint-disable camelcase */
/* eslint-disable import/named */
/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */

import querystring from 'querystring';
import { API } from './API';

export const salonList = (params) => {
  const result = API.get(`${process.env.REACT_APP_API_URL}/salon/list`, params);
  return result;
};
export const salonCreate = (params) => {
  const result = API.post(`${process.env.REACT_APP_API_URL}/salon/create`, params);
  return result;
};

export const salonGet = (params) => {
  const result = API.get(`${process.env.REACT_APP_API_URL}/salon/get`, params);
  return result;
};

export const guessSalonGet = (params) => {
  const result = API.get(`${process.env.REACT_APP_API_URL}/guess/salon/get`, params);
  return result;
};

export const salonUpdateWorkingTime = (params) => {
  const result = API.post(`${process.env.REACT_APP_API_URL}/salon/update-working-time`, params);
  return result;
};

export const salonUpdateSettings = (params, onUploadProgress) => {
  const result = API.postForm(`${process.env.REACT_APP_API_URL}/salon/update`, params, onUploadProgress);
  return result;
};

export const salonService = {
  salonList,
  salonCreate,
  salonGet,
  guessSalonGet,
  salonUpdateWorkingTime,
  salonUpdateSettings,
};
