import React, { Component } from 'react'
import ShiftManagement from './ShiftManagement'
import "../../../styles/components/shiftManagement.scss"

export default class ShiftMain extends Component {
    render() {
        return (
            <div className='container-fluid'>
                <h3 className='title-case text-dark text-center mb-20' style={{fontSize:"26px"}}>予約可能時間設定</h3>
                <ShiftManagement/>
            </div>
        )
    }
}
