import React, { Component } from 'react'
import { connect } from 'react-redux';
import { staffReducer } from '../../../reducers/staffReducer';
import { getGuessChoice } from '../../../actions';
import { TYPES } from '../../../actions';
import _ from 'lodash';
import Func from '../../../utils/Func';

class BoardInforOption extends Component {
  constructor(props) {
    super(props);
    this.state = {
      optionDetail: {},
    };
  }

  componentDidMount() {
  }

  UNSAFE_componentWillReceiveProps(props) {
    const { type } = props.actionNotify;
    switch (type) {
      case TYPES.ACTION_GET_GUEST_CHOICE:
        const option = _.get(props, 'actionNotify.guessChoicing.option', {});
        this.setState({ optionDetail: {
          totalTime: `${_.get(option, 'total_time', 0) * 30}`,
          option: _.get(option, 'name', ''),
          totalPrice: _.get(option, 'price', 0),
          estimated_total_time: _.get(option, 'estimated_total_time', 0)
        } });
        break;
      default:
        break;
    }
  }
  formatPrice = (price) => {
    if (typeof price === 'number') {
      price = price.toString();
    }

    if (typeof price !== 'string' || price.trim() === '') {
      return '0';
    }

    let [integerPart = '', decimalPart = ''] = price.split('.');

    let formattedIntegerPart = parseInt(integerPart).toLocaleString();

    return decimalPart ? `${formattedIntegerPart}.${decimalPart}` : `${formattedIntegerPart}`;
  }

  render() {
    const {optionDetail} = this.state;
    console.log("optiondetal",optionDetail)
    // const totalTime = Func.convertToJapaneseTime(Number(_.get(optionDetail, 'totalTime', 0)) / 30);
    return (
      <div className='w-100 mb-20 text-dark'>

        <div style={{ border: "1px solid black" }} className='w-100 text-dark p-10'>
          <h5 className=' w-100 d-flex flex-start title-boardInfor p-10' style={{fontSize:"16px"}}>
          選択済みコース

          </h5>

          <div className='board-infor-option w-100 d-flex justify-content-between p-10 font-weight-bold' style={{fontSize:"18px"}}>
            <p className='board-name-option w-70' style={{fontSize:"16px"}}>{optionDetail.option}</p>
            <div className='board-time-price d-flex flex-row justify-content-end w-25'>
              <p style={{ color: "red" }}>{this.formatPrice(optionDetail.totalPrice)}円</p>
              <p className='ml-20'>{optionDetail.estimated_total_time === null ? optionDetail.totalTime : optionDetail.estimated_total_time}分</p>

            </div>
          </div>
        </div>

        {/* <div className='mt-30 w-70 d-flex flex-row  text-center font-weight-bold' style={{ maxWidth: 400, minHeight: 50, border: "1px solid black" }}>
          <div style={{ borderRight: "2px solid black" }} className=' w-70 d-flex align-items-center justify-content-center'>
            <h5 style={{fontSize:"16px"}} className='p-0 m-0'>所要時間合計（目安）</h5>

          </div>
          <div  className='d-flex align-items-center w-30 justify-content-center'>

            <h5 style={{fontSize:"16px"}} className='p-0 m-0'>{totalTime}</h5>
          </div>

        </div> */}

        <div className='sp-view w-100 d-flex justify-content-end align-items-center py-10'>
          <p className='m-0'>◎：予約可能</p>
          <p className='ml-40 m-0'>×：予約不可能</p>

        </div>
      </div>
    )
  }
}

function mapStateToProps(state) {
    const {  staffReducer } = state;

    return {
        actionNotify: staffReducer.actionNotify
    };
}


export default connect(mapStateToProps, { 
  getGuessChoice 
})(BoardInforOption)