import React, { Component } from 'react';
import { connect } from 'react-redux';
import './../../../styles/components/listmanage.scss';
import './../../../styles/components/deletestore.scss';
import './../../../styles/components/updatestore.scss';
import './../../../styles/common/_card.scss';
import { getListServices, getService, deleteService, createService, serviceCheckPriority, notifyAction } from '../../../actions';
import { TYPES } from '../../../actions';
import _ from 'lodash';
import Func from '../../../utils/Func';
import { LANGUAGES, getTranslate, notify } from '../../../utils';

class Listmenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showUpdateStore: false,
      showList: true,
      showDeleteStore: false,
      showSearchResult: false,
      showResult: true,
      isEmpty: false,
      searchQuery: '',
      textValue: '',
      maxLength: 200,
      items: [],
      itemsRendered: [],
      currentPage: 1,
      itemsPerPage: 5,
      itemEditing: {},
      isAddNewService: false,
      errorMessages: {
        'item-name': '',
        'item-price': '',
        'item-frames': '',
        'item-priority': '',
      },
      isValid: true,
      hiddenCards: {},
      isMobile: window.innerWidth < 769,
      formMode: '',
      form: {
        file: null,
        file_path: null,
        image_url: '',
        db_id : null,
        name : "",
        price : "",
        frames : null,
        priority : null,
      },
      uploadPercentComplete: 0,
    };
  }

  onUploadProgress = () => {};

  UNSAFE_componentWillReceiveProps(props) {
    console.log('Listmenu UNSAFE_componentWillReceiveProps props: ', props);
    let { type } = props.actionNotify;
    const { errorCode, message } = Func.getError({ ...props });
    switch (type) {
      case TYPES.ACTION_GET_LIST_SERVICES_SUCCESSFULLY:
        this.setState({ items: [...props.actionNotify.services],
          form : {},
          uploadPercentComplete : 0
         }, () => {
          this.handleSearch();
        });
        break;
      case TYPES.ACTION_GET_LIST_SERVICES_FAIL:
        break;
      case TYPES.ACTION_CREATE_SERVICE_SUCCESSFULLY:
        this.setState({ showUpdateStore: false, showList: true, itemEditing: {} }, () => {
          this.props.getListServices();
        });

        if (this.state.formMode == 'insert') {
          notify(getTranslate(LANGUAGES.TEXT_CREATE_OPTION_SUCCESSFULLY), 'success');
        } else {
          notify(getTranslate(LANGUAGES.TEXT_UPDATE_OPTION_SUCCESSFULLY), 'success');
        }

        break;
      case TYPES.ACTION_CREATE_SERVICE_FAIL:
        break;
      case TYPES.ACTION_DELETE_SERVICE_SUCCESSFULLY:
        this.setState({ showDeleteStore: false, showUpdateStore: false, showList: true, itemEditing: {} }, () => {
          this.props.getListServices();
        });
        notify(getTranslate(LANGUAGES.TEXT_DELETE_OPTION_SUCCESSFULLY), 'success');
        break;
      case TYPES.ACTION_DELETE_SERVICE_FAIL:
        break;
      case TYPES.ACTION_SERVICE_PRIORITY_INVALID:
        let msgError = getTranslate(LANGUAGES.TEXT_SERVICE_PRIORITY_IS_EXIST__PLEASE_ENTER_NEW_PRIORITY);
        notify(msgError, 'error');
        this.setState({
          errorMessages: {
            ...(this.state.errorMessages || {}),
            'item-priority': msgError.replace('\n', '<br />')
          },
        });
        break;
      case TYPES.ACTION_SERVICE_PRIORITY_VALID:
        const formData = new FormData();
        formData.append('file', this.state.form.file || '');
        formData.append('name', this.itemToSave.name || '');
        formData.append('description', this.itemToSave.description || '');
        formData.append('total_time', this.itemToSave.total_time || '');
        formData.append('estimated_total_time', this.itemToSave.estimated_total_time || '');
        formData.append('price', this.itemToSave.price || '');
        formData.append('priority', this.itemToSave.priority || '');
        formData.append('image_url', this.state.form.image_url || '');
        if(this.itemToSave.db_id)formData.append('db_id', this.itemToSave.db_id || '');

        this.props.createService(formData, (percentCompleted) => {
          this.setState({
            uploadPercentComplete: Math.max(percentCompleted - 5, 1),
          });
          console.log('percentCompleted', percentCompleted);
        });

        this.setState({
          errorMessages: {
            'item-name': '',
            'item-price': '',
            'item-frames': '',
            'item-priority': '',
          },
          isValid: true,
        });
        break;
      default:
        break;
    }

    // this.props.notifyAction({ type, error_code: errorCode, message });
  }

  componentDidMount() {
    this.props.getListServices();
    window.addEventListener('resize', this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }
  handleResize = () => {
    this.setState({ isMobile: window.innerWidth < 769 });
  };

  handleTextChange = (event) => {
    const target = event.target;
    const id = target.id;
    const value = target.value;
    let { itemEditing } = this.state;

    const errorMessages = {
      'item-name': '',
      'item-price': '',
      'item-frames': '',
      'item-priority': '',
      'item-estimated_total_time': '',
    };

    let isValid = true;

    let errorMess = '';
    switch (id) {
      case 'item-name':
        itemEditing.content = value;
        errorMess = !value.trim() ? 'コース内容を入力してください' : '';
        errorMessages[id] = errorMess;
        break;
      case 'item-price':
        itemEditing.price = value;
        errorMess = !value.trim() ? '表示価格を入力してください' : Number(value) < 0 || isNaN(Number(value)) ? '表示価格を正しく入力してください' : '';
        errorMessages[id] = errorMess;
        break;
      case 'item-frames':
        itemEditing.frames = value;
        errorMess = !value.trim() ? '枠数を入力してください' : Number(value) < 1 || isNaN(Number(value)) ? '枠数を正しく入力してください' : '';
        errorMessages[id] = errorMess;
        break;
      case 'item-priority':
        itemEditing.priority = value;
        errorMess = !value.trim() ? '表示順番を正しく入力してください' : Number(value) < 0 || isNaN(Number(value)) ? '表示順番を正しく入力してください' : '';
        errorMessages[id] = errorMess;
        break;
      case 'item-estimated_total_time':
        itemEditing.estimated_total_time = value;
        errorMess = value === "" ? '' : Number(value) < 0 || isNaN(Number(value)) ? '目安時間を正しく入力してください' : '';
        console.log('errorMess', errorMess);
        errorMessages[id] = errorMess;
        break;
      default:
        break;
    }

    for (const key in errorMessages) {
      if (errorMessages[key]) {
        isValid = false;
        break;
      }
    }

    this.setState({
      itemEditing: { ...itemEditing },
      errorMessages: { ...errorMessages },
      isValid: isValid,
    });

    console.log('isvalid', isValid);
  };
  handleEditClick = (id) => {
    const item = _.get(this.state.items, id);
    console.log('id', id, this.state.items, item);
    this.setState({
      errorMessages: {
        'item-name': '',
        'item-price': '',
        'item-frames': '',
        'item-priority': '',
      },
      isValid: true,
    });
    this.setState({
      showUpdateStore: true,
      showList: false,
      itemEditing: { ...item },
      isAddNewService: false,
      formMode: 'update',
      form: {
        image_url: item.image_url,
      }
    });
  };
  handleEditSubmitClick = (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    this.form = form;
    let { itemEditing, errorMessages } = this.state;
    const db_id = _.get(itemEditing, 'db_id', null);
    const name = document.getElementById('item-name').value;
    const price = document.getElementById('item-price').value;
    const frames = document.getElementById('item-frames').value;
    const priority = document.getElementById('item-priority').value;
    const estimated_total_time = document.getElementById('item-estimated_total_time').value;

    let newErrorMessages = { ...errorMessages };

    newErrorMessages['item-name'] = !name.trim() ? 'コース内容を入力してください' : errorMessages['item-name'];
    newErrorMessages['item-price'] = !price.trim() ? '表示価格を入力してください' : errorMessages['item-price'];
    newErrorMessages['item-frames'] = !frames.trim() ? '枠数を入力してください' : errorMessages['item-frames'];
    newErrorMessages['item-priority'] = !priority.trim() ? '表示順番を正しく入力してください' : errorMessages['item-priority'];
    newErrorMessages['item-estimated_total_time'] = !estimated_total_time.trim() ? '' : errorMessages['item-estimated_total_time'];
    let isValid = this.state.isValid;
    for (const key in newErrorMessages) {
      if (newErrorMessages[key]) {
        isValid = false;
        break;
      }
    }

    this.setState({
      errorMessages: newErrorMessages,
      isValid: isValid,
    });

    if (isValid) {
      let itemToSave = {
        name: name,
        description: '',
        price: price,
        total_time: frames,
        estimated_total_time: estimated_total_time,
        priority: priority,
      };
      if (!this.state.isAddNewService) {
        itemToSave.db_id = db_id;
      }

      this.props.serviceCheckPriority({ priority, id: db_id });

      this.itemToSave = itemToSave;
    }
    console.log('isvalid', isValid);
  };
  onHandleAddService = () => {
    this.setState({ showUpdateStore: true, showList: false, isAddNewService: true, itemEditing: {} });
    this.setState({
      errorMessages: {
        'item-name': '',
        'item-price': '',
        'item-frames': '',
        'item-priority': '',
        'item-estimated_total_time': '',
      },
      isValid: false,
      formMode: 'insert',
      form: {
        
      }
    });
  };
  handleDeleteClick = (id) => {
    const item = _.get(this.state.items, id);
    this.setState({
      showDeleteStore: true,
      showList: false,
      itemEditing: { ...item },
      formMode: 'delete',
    });
  };
  handleEditCancelClick = () => {
    this.setState({ showUpdateStore: false, showList: true, itemEditing: {}, formMode: '' });
  };
  handleDeleteCancelClick = () => {
    this.setState({ showDeleteStore: false, showList: true, formMode: '' });
  };
  handleDeleteSubmitClick = () => {
    this.props.deleteService({ id: this.state.itemEditing.db_id });
  };
  handleSearchChange = (e) => {
    const value = e.target.value;
    this.setState(
      {
        searchQuery: value,
      },
      () => {
        if (!value) {
          this.handleSearch();
        }
      }
    );
  };
  onEnter = (e) => {
    if (e.key === 'Enter') {
      this.handleSearch();
    }
  };
  handlePageChange = (event, pageNumber) => {
    event.preventDefault();
    this.setState({ currentPage: pageNumber });
  };
  handleSearch = () => {
    const filteredItems = this.state.items.filter((item) => item.content.toLowerCase().includes(this.state.searchQuery.toLowerCase()));

    const sortedItems = filteredItems.sort((a, b) => {
      if (a.priority === 0 && b.priority !== 0) {
        return 1;
      } else if (a.priority !== 0 && b.priority === 0) {
        return -1;
      } else if (a.priority !== b.priority) {
        return a.priority - b.priority;
      } else {
        return b.db_id - a.db_id;
      }
    });

    this.setState({ itemsRendered: sortedItems, currentPage: 1 });
  };

  toggleCardVisibility = (id) => {
    const { itemsRendered } = this.state;
    const updatedItems = itemsRendered.map((item) => {
      if (item.id === id) {
        return {
          ...item,
          isHidden: !item.isHidden,
        };
      }
      return item;
    });

    this.setState(
      {
        itemsRendered: updatedItems,
      },
      console.log(updatedItems)
    );
  };

  formatPrice = (price) => {
    let numericPart = price.slice(0, -1);
    let currencySymbol = price.slice(-1);
    let [integerPart, decimalPart] = numericPart.split('.');
    let formattedIntegerPart = parseInt(integerPart).toLocaleString();
    return decimalPart ? `${formattedIntegerPart}.${decimalPart}${currencySymbol}` : `${formattedIntegerPart}${currencySymbol}`;
  };

  onChangeFormField = (e, field) => {
    const value = e.target.value || '';
    let isFileField = field == 'file';
    let isClearFile = field == 'clear_file';
    let newForm = {
      ...this.state.form,
      [field]: isFileField ? _.get(e, 'target.files[0]') : value.trim(),
    };
    if (isFileField) {
      newForm.file_path = value;
      newForm.image_url = URL.createObjectURL(_.get(e, 'target.files[0]'));
      newForm.file = _.get(e, 'target.files[0]');
    }
    if (isClearFile) {
      delete newForm[field];
      newForm = {
        ...newForm,
        file: null,
        file_path: null,
        image_url: '',
      };
    }

    console.log('onChangeFormField', field, value);

    this.setState({
      form: newForm,
      msg: {},
      uploadPercentComplete: isClearFile ? 0 : this.state.uploadPercentComplete,
    });

    console.log('newForm', newForm);
    // if (this.form) this.form.classList.remove('was-validated');
  };

  validateFileSize = (e, field) => {
    let input = e.currentTarget;
    const maxSize = 20 * 1024 * 1024; // 20MB in bytes
    const file = input.files[0];

    if (file && file.size > maxSize) {
      notify('ファイルサイズが20MBを超えています。\n20MB以下のファイルを選択してください。', 'error');
      input.value = ''; // Clear the input

      return;
    }

    this.onChangeFormField(e, field);
  };

  validateFileSizeAndDimensions = (e, field) => {
    let input = e.currentTarget;
    const maxSize = 20 * 1024 * 1024; // 20MB in bytes
    const minRecommendationWidth = 600; // Maximum width in pixels
    const minRecommendationHeight = 600; // Maximum height in pixels
    const file = input.files[0];
    let isImgError = false;

    if (!file) {
      return;
    }

    // Validate file size
    if (file.size > maxSize) {
      notify('ファイルサイズが20MBを超えています。\n20MB以下のファイルを選択してください。', 'error');
      input.value = ''; // Clear the input
      return;
    }

    // Validate image dimensions if it's an image file
    const validImageTypes = ['image/jpeg', 'image/png', 'image/gif'];
    if (validImageTypes.includes(file.type)) {
      const img = new Image();
      const objectUrl = URL.createObjectURL(file);

      img.onload = () => {
        if (img.width < minRecommendationWidth || img.height < minRecommendationHeight) {
          notify(`画像サイズが600x600未満です。\n600x600以上の画像をお勧めいたします。`, 'warn');
        }
        URL.revokeObjectURL(objectUrl); // Free up memory
      };

      img.onerror = () => {
        notify('無効な画像ファイルです。', 'error');
        input.value = ''; // Clear the input
        URL.revokeObjectURL(objectUrl);
        isImgError = true;
      };

      img.src = objectUrl;
    }
    this.onChangeFormField(e, field);
  };

  render() {
    let { currentPage, itemsPerPage, itemsRendered, itemEditing, isMobile } = this.state;
    const image_url = this.state.form.image_url || "";
    console.log('image_url: ', image_url);
    if (!_.isEmpty(itemEditing.price)) {
      itemEditing.price = itemEditing.price.replace('円', '');
    }
    console.log('itemsRendered: ', itemsRendered);

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = itemsRendered.slice(indexOfFirstItem, indexOfLastItem);

    const pageNumbers = [];
    for (let i = 1; i <= Math.ceil(itemsRendered.length / itemsPerPage); i++) {
      pageNumbers.push(i);
    }

    setInterval(() => {
      let $bar = document.querySelector('#bar');
      if($bar)$bar.style.width = ($bar.parentNode.offsetWidth * this.state.uploadPercentComplete) / 100 + 'px';
    }, 40);

    return (
      <div>
        <div style={{ display: this.state.showList ? 'flex' : 'none' }} className="list-it container-fluid">
          <h1 style={{ color: 'black', fontSize: '26px' }}>コースメニュー登録</h1>
          <button className="btn1 btn-success btn-custom-sm" style={{ maxWidth: '700px', height: '50px' }} onClick={this.onHandleAddService}>
            新規登録
          </button>

          <div className="search-row" style={{ maxWidth: '700px' }}>
            <input
              className="input-custom-sm"
              style={{ height: '42px', width: '80%' }}
              placeholder="キーワード検索"
              type="text"
              value={this.state.searchQuery}
              onChange={this.handleSearchChange}
              onKeyDown={this.onEnter}
            />
            <button style={{ height: 42, width: '15%' }} className="btn2 btn-search btn-success" onClick={this.handleSearch}>
              <i className="fas fa-search"></i>
            </button>
          </div>

          <div className="list-row w-100 mt-30 d-flex justify-content-center">
            {!isMobile ? (
              <table className="table table-bordered table-custom mt-4 tb-optionmanage" style={{ maxWidth: '1200px' }}>
                <thead className="bg-success">
                  <tr className="tb-row1 ">
                    <th className="w-50" id="th-name" style={{ maxWidth: '600px' }}>
                    コース内容

                    </th>
                    <th className="num-2" style={{ maxWidth: '120px' }}>
                      画像
                    </th>
                    <th className="num" style={{ maxWidth: '100px' }}>
                      表示順番
                    </th>
                    <th className="num-2" style={{ maxWidth: '200px' }}>
                      表示価格
                    </th>
                    <th className="num" style={{ maxWidth: '100px' }}>
                      枠数
                    </th>
                    <th className="num" style={{ maxWidth: '100px' }}>
                      お客様に見せる目安時間（分）
                    </th>
                    <th className="num" style={{ maxWidth: '100px' }}>
                      変更
                    </th>
                    <th className="num" style={{ maxWidth: '100px' }}>
                      削除
                    </th>
                  </tr>
                </thead>

                <tbody>
                  {currentItems.map((item) => (
                    <tr key={item.id} className="tb-row">
                      <td style={{ textAlign: 'start' }}>
                        <span>{item.content}</span>
                      </td>
                      <td style={{}}>
                        {item.image_url && <img src={item.image_url} alt="" style={{
                          height: '100px',
                          width: '100px',
                          objectFit: 'contain',
                          border: "1px solid lightgray",
                          margin: "auto"
                        }} />}
                        {!item.image_url && <img src="https://yosapark-sys.com/static/images/yosapark-course-default-image_01.png" alt="" style={{
                          height: '100px',
                          width: '100px',
                          objectFit: 'contain',
                          border: "1px solid lightgray",
                          margin: "auto"
                        }} />
                        // <p style={{
                        //   height: '100px',
                        //   width: '100px',
                        //   backgroundColor: 'lightgray',
                        //   textAlign: 'center',
                        //   lineHeight: '100px',
                        //   border: "1px solid lightgray",
                        //   margin: "auto"
                        // }}>
                        //   NO IMAGE
                        // </p>
                        }
                      </td>
                      <td>{item.priority}</td>
                      <td>{this.formatPrice(item.price)}</td>
                      <td>{item.frames}</td>
                      <td>{item.estimated_total_time}</td>
                      <td className="table-td_icon">
                        <i style={{ cursor: 'pointer' }} className="fas fa-edit table-td_icon" onClick={() => this.handleEditClick(item.id)}></i>
                      </td>
                      <td className="table-td_icon">
                        <i style={{ cursor: 'pointer', color: 'red' }} className="fas fa-trash-alt table-td_icon" onClick={() => this.handleDeleteClick(item.id)}></i>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : (
              <div className="w-100">
                {currentItems.map((item) => (
                  <div key={item.id} className={`card card-custom-ms text-dark w-100 card-optionmanage`}>
                    <div className="card-body">
                      <div className="card-content">
                        <div className="card-list">
                          <h4 className="card-title card-name">コース内容
                          </h4>
                          <p className="card-text">{item.content}</p>

                         
                        </div>
                        <div className="card-list">
                          <h4 className="card-title card-name">画像
                          </h4>
                          {/* <p className="card-text">{item.content}</p> */}
                            {item.image_url && <img src={item.image_url} alt="" style={{ height: '100px', width: '100px' }} />}
                            {!item.image_url && 
                            <img src="https://yosapark-sys.com/static/images/yosapark-course-default-image_01.png" alt="" style={{ height: '100px', width: '100px' }} />
                            // <p style={{ height: '100px', width: '100px', backgroundColor: 'lightgray', textAlign: 'center', lineHeight: '100px' }}>
                            //   NO IMAGE
                            //   </p>
                            }
                        </div>
                        {!item.isHidden && (
                          <div>
                            <div className="card-list">
                              <h4 className="card-title card-priority">表示順番</h4>
                              <p className="card-text">{item.priority}</p>
                            </div>

                            <div className="card-list">
                              <h4 className="card-title card-price">表示価格</h4>
                              <p className="card-text">{this.formatPrice(item.price)}</p>
                            </div>

                            <div className="card-list">
                              <h4 className="card-title card-quantity">枠数</h4>
                              <p className="card-text">{item.frames}</p>
                            </div>

                            <div className="card-list">
                              <h4 className="card-title card-quantity">お客様に見せる目安時間（分）</h4>
                              <p className="card-text">{item.estimated_total_time}</p>
                            </div>

                            <div className="card-list card-list-edit">
                              <h4 className="card-title card-title-icon">アクション</h4>
                              <div className="card-list-icon">
                                <i style={{ cursor: 'pointer' }} className="fas fa-edit card-icon" onClick={() => this.handleEditClick(item.id)}></i>
                                <i style={{ cursor: 'pointer', color: 'red' }} className="fas fa-trash-alt card-icon" onClick={() => this.handleDeleteClick(item.id)}></i>
                              </div>
                            </div>
                          </div>
                        )}

                        {/* <div className="card-toggle">
                          <button onClick={() => this.toggleCardVisibility(item.id)} className="btn btn-card-toggle">
                            {item.isHidden ? '情報を表示' : '情報を隠す'}
                          </button>
                        </div> */}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
          <nav>
            <ul className="pagination">
              {pageNumbers.map((number) => (
                <li key={number} className={`page-item ${currentPage === number ? 'active' : ''}`}>
                  <a onClick={(e) => this.handlePageChange(e, number)} href="#" className="page-link">
                    {number}
                  </a>
                </li>
              ))}
            </ul>
          </nav>
        </div>
        <div
          style={{ display: this.state.showDeleteStore ? 'flex' : 'none', top: 0, left: 0, backgroundColor: '#f2f2f2' }}
          className="justify-content-center position-fixed w-100 h-100 flex-column align-items-center"
        >
          <div
            style={{ display: 'flex', maxWidth: 700, width: '100%', height: 'fit-content', backgroundColor: '#fff', border: '1px solid rgba(0, 0, 0, 0.125)', borderRadius: '0.25rem' }}
            className="bg px-40 py-20 position-relative"
          >
            <div className=" contain-main d-flex flex-column align-items-center p-0">
              <h1 style={{ color: 'black', fontSize: '26px' }}>コース削除
              </h1>
              <p style={{ color: 'black', fontSize: '16px' }}>削除すると以下の情報が全て失われます</p>
              <div className="content-main w-100 m-0" style={{ padding: '1.25rem', minHeight: '1px', flex: '1 1 auto' }}>
                <li className="d-flex flex-wrap" style={{ fontSize: '16px' }}>
                  <h5 className="delete-title" style={{ margin: '2px 0 0 0', fontSize: '16px', minWidth: 120 }}>
                    ・コース内容：
                  </h5>{' '}
                  {_.get(itemEditing, 'content', '')}
                </li>
                <li className="d-flex flex-wrap" style={{ fontSize: '16px' }}>
                  <h5 style={{ margin: '2px 0 0 0', fontSize: '16px', minWidth: 70 }}>・表示価格：</h5> {_.get(itemEditing, 'price', '')}円
                </li>
                <li className="d-flex flex-wrap" style={{ fontSize: '16px' }}>
                  <h5 style={{ margin: '2px 0 0 0', fontSize: '16px', minWidth: 70 }}>・枠数：</h5> {_.get(itemEditing, 'frames', '')}
                </li>
                <li className="d-flex flex-wrap" style={{ fontSize: '16px' }}>
                  <h5 style={{ margin: '2px 0 0 0', fontSize: '16px', minWidth: 70 }}>・表示順番：</h5> {_.get(itemEditing, 'priority', 0)}
                </li>
              </div>
              {/* <div className="row-btn mb-0" style={{ width: "100%" }}>
                <button style={{ maxHeight: 60, minHeight: 42, fontSize: "16px" }} type="submit" className="btnx btn-cancel font-weight-bold m-0" onClick={this.handleDeleteCancelClick}>
                  キャンセル
                </button>
                <button style={{ maxHeight: 60, minHeight: 42, fontSize: "16px", maxWidth: 150 }} type="submit" className="btnx btn-success font-weight-bold " onClick={this.handleDeleteSubmitClick}>
                  削除
                </button>
              </div> */}

              <div className="d-flex justify-content-between mt-20 w-100">
                <button className="btn btn-light font-weight-bold" style={{ border: '1px solid', minWidth: 120, fontSize: 22 }} onClick={this.handleDeleteCancelClick}>
                  キャンセル
                </button>
                <button className="btn btn-success font-weight-bold" style={{ minWidth: 120, fontSize: 22 }} onClick={this.handleDeleteSubmitClick}>
                  削除
                </button>
              </div>
            </div>
          </div>
        </div>
        <div style={{ display: this.state.showUpdateStore ? 'flex' : 'none' }} className="list-it">
          <h1 style={{ color: 'black', fontSize: '26px' }}>コース内容
          </h1>
          <form className="update-form" onSubmit={this.handleEditSubmitClick} id="updated-form">
            <div className="d-flex flex-column text-dark m-20">
              <div className="d-flex flex-row justify-content-between">
                <div className="d-flex flex-row">
                  <p style={{ color: 'black', fontSize: '16px', float: 'left', fontWeight: 700 }}>コース内容
                  </p>
                  <span style={{ fontSize: '16px' }} className="span-req req">
                    必須
                  </span>
                </div>
                <p style={{ color: 'black', fontSize: '14px', float: 'right' }}>
                  {this.state.textValue.length} / {this.state.maxLength}
                </p>
              </div>
              <textarea className="m-0" id="item-name" value={_.get(itemEditing, 'content', '')} onChange={this.handleTextChange} maxLength={this.state.maxLength}></textarea>

              <p className="m-0 error-content error-red">{this.state.errorMessages['item-name']}</p>
            </div>

            <div className="d-flex flex-column text-dark m-20">
              <div className="d-flex flex-row text-center">
                <p style={{ color: 'black', fontSize: '16px', float: 'left', fontWeight: 700 }}>表示価格</p>
                <span style={{ fontSize: '16px' }} className="span-req req">
                  必須
                </span>
              </div>
              <input className="m-0" id="item-price" value={_.get(itemEditing, 'price', '')} onChange={this.handleTextChange}></input>
              <p className={`m-0 error-price error-red`}>{this.state.errorMessages['item-price']}</p>
            </div>

            <div className="d-flex flex-column text-dark m-20">
              <div className="d-flex flex-row text-center">
                <p style={{ color: 'black', fontSize: '16px', float: 'left', fontWeight: 700 }}>枠数（1枠＝30分）</p>
                <span style={{ fontSize: '16px' }} className="span-req req">
                  必須
                </span>
              </div>

              <input className="m-0" id="item-frames" value={_.get(itemEditing, 'frames', '')} onChange={this.handleTextChange}></input>
              <p className={`m-0 error-price error-red`}>{this.state.errorMessages['item-frames']}</p>
            </div>

            <div className="d-flex flex-column text-dark m-20">
              <div className="d-flex flex-row text-center">
                <p style={{ color: 'black', fontSize: '16px', float: 'left', fontWeight: 700 }}>お客様に見せる目安時間（分）</p>
                <span style={{ fontSize: '16px' }} className="span-req">
                  任意
                </span>
              </div>

              <input className="m-0" id="item-estimated_total_time" value={_.get(itemEditing, 'estimated_total_time', '')} onChange={this.handleTextChange}></input>

              <p className={`m-0 error-price error-red`} dangerouslySetInnerHTML={{__html: this.state.errorMessages['item-estimated_total_time']}}></p>
            </div>

            <div className="d-flex flex-column text-dark m-20">
              <div className="d-flex flex-row text-center">
                <p style={{ color: 'black', fontSize: '16px', float: 'left', fontWeight: 700 }}>表示順番（0は非表示）</p>
                <span style={{ fontSize: '16px' }} className="span-req req">
                  必須
                </span>
              </div>

              <input className="m-0" id="item-priority" value={_.get(itemEditing, 'priority', '')} onChange={this.handleTextChange}></input>

              <p className={`m-0 error-price error-red`} dangerouslySetInnerHTML={{__html: this.state.errorMessages['item-priority']}}></p>
            </div>
            
            <div className="d-flex flex-column text-dark m-20">
              <div className="d-flex flex-row text-center">
                <p style={{ color: 'black', fontSize: '16px', float: 'left', fontWeight: 700 }}>画像</p>
                <span style={{ fontSize: '16px' }} className="span-req">
                  任意
                </span>
              </div>

              {!image_url && (
                <div style={{ padding: '0.3rem', border: '1px solid gray', display: 'flex', borderRadius: '0.25rem', justifyContent: 'space-between', alignItems: 'center' }}>
                  <p style={{ margin: '0px', textAlign: 'left', color: 'black' }}>{this.state.form.file_path || 'ファイルを選択されていません'}</p>
                  <label
                    for="fileInput"
                    style={{
                      color: 'black',
                      margin: '0',
                      padding: '10px',
                      border: '1px solid #ced4da',
                      borderRadius: '0.25rem',
                      fontSize: '12px',
                      background: 'rgba(211, 211, 211, 0.3)',
                      cursor: 'pointer',
                      userSelect: 'none',
                    }}
                  >
                    ファイルを選択
                  </label>
                  <input type="file" id="fileInput" accept="image/*" onChange={(e) => this.validateFileSizeAndDimensions(e, 'file')} style={{ width: '7rem' }} />
                </div>
              )}

              {image_url && (
                <div style={{ position: 'relative', display: 'flex', justifyContent: 'left', alignItems: 'start', border: '1px solid gray', borderRadius: '0.25rem', padding: '0.3rem' }}>
                  <img src={image_url} style={{ height: '100px' }} />
                  <button type="button" style={{ background: 'transparent', border: 'none', marginLeft: '-1rem', marginTop: '-0.3rem' }} onClick={(e) => this.onChangeFormField(e, 'clear_file')}>
                    <i className="fa fa-times-circle" aria-hidden="true" style={{ fontSize: '1.4rem' }}></i>
                  </button>
                </div>
              )}

              {image_url && this.state.uploadPercentComplete > 0 && (
                <div className="mb-10 mt-10" style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                  <div className="progress" style={{ width: 'calc(100% - 2.5rem)', height: '0.7rem', borderRadius: '1rem' }}>
                    <div className="progress-bar bar" id="bar" role="progressbar" aria-valuenow={this.state.uploadPercentComplete} aria-valuemin="0" aria-valuemax="100" style={{borderRadius: '1rem'}}></div>
                  </div>
                  <div style={{ color: 'black', fontSize: '14px' }}>{this.state.uploadPercentComplete}%</div>
                </div>
              )}

              {/* <p className={`m-0 error-price error-red`} dangerouslySetInnerHTML={{__html: this.state.errorMessages['item-priority']}}></p> */}
            </div>
          </form>
          <div className="row-btn" style={{ maxWidth: 700, width: '56vw' }}>
            <button type="button" style={{ border: '1px solid', minWidth: 150, fontSize: 22 }} className="btn btn-light font-weight-bold " onClick={this.handleEditCancelClick}>
              キャンセル
            </button>
            {/* add target to form */}
            <button style={{ minWidth: 120, fontSize: 22 }} type="submit" className="btn btn-success font-weight-bold" form="updated-form">
              OK
            </button>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { staffReducer } = state;

  return {
    // menuDetail: staffReducer.menuDetail,
    actionNotify: staffReducer.actionNotify,
  };
}

export default connect(mapStateToProps, {
  getListServices,
  deleteService,
  getService,
  createService,
  serviceCheckPriority,
  notifyAction,
})(Listmenu);
