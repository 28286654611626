import React from 'react';
import PropTypes from 'prop-types';
import Header from '../includes/Header';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import HeaderUser from '../includes/HeaderUser';

function Master({ children }) {
  const location = useLocation();
  const isLoginPage = location.pathname === '/login';

  return (
    <div id={'site'}>
      { <Header />}

      {children}
    </div>
  );
}

Master.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Master;
