import { TYPES } from './types';
import { salonService } from '../services';

export const salonList = (params) => async (dispatch) => {
  try {
    const result = await salonService.salonList(params);
    const { data } = result;
    if (data.status != 'error') {
      dispatch({
        type: TYPES.ACTION_SALON_GET_LIST_SUCCESS,
        payload: {
          data: data,
        },
      });
    } else {
      dispatch({
        type: TYPES.ACTION_CALL_API_FAIL,
        payload: {
          data: data,
        },
      });
    }
  } catch (error) {
    dispatch({
      type: TYPES.ACTION_CALL_API_FAIL,
      payload: {
        data: error,
        message: error,
      },
    });
  }
};

export const salonCreate = (params) => async (dispatch) => {
  try {
    const result = await salonService.salonCreate(params);
    const { data } = result;
    if (data.status != 'error') {
      dispatch({
        type: TYPES.ACTION_SALON_SAVE_SUCCESS,
        payload: {
          data: data,
        },
      });
    } else {
      dispatch({
        type: TYPES.ACTION_CALL_API_FAIL,
        payload: {
          data: data,
        },
      });
    }
  } catch (error) {
    dispatch({
      type: TYPES.ACTION_CALL_API_FAIL,
      payload: {
        data: error,
        message: error,
      },
    });
  }
};

export const guessSalonGet = (params) => async (dispatch) => {
  try {
    const result = await salonService.guessSalonGet(params);
    const { data } = result;
    if (data.status != 'error') {
      dispatch({
        type: TYPES.ACTION_GET_GUESS_SALON_SUCCESS,
        payload: {
          data: data,
        },
      });
    } else {
      dispatch({
        type: TYPES.ACTION_CALL_API_FAIL,
        payload: {
          data: data,
        },
      });
    }
  } catch (error) {
    dispatch({
      type: TYPES.ACTION_CALL_API_FAIL,
      payload: {
        data: error,
        message: error,
      },
    });
  }
};

export const salonUpdateWorkingTime = (params) => async (dispatch) => {
  try {
    const result = await salonService.salonUpdateWorkingTime(params);
    const { data } = result;
    if (data.status != 'error') {
      dispatch({
        type: TYPES.ACTION_SALON_UPDATE_WORKING_TIME_SUCCESS,
        payload: {
          data: data,
        },
      });
    } else {
      dispatch({
        type: TYPES.ACTION_CALL_API_FAIL,
        payload: {
          data: data,
        },
      });
    }
  } catch (error) {
    dispatch({
      type: TYPES.ACTION_CALL_API_FAIL,
      payload: {
        data: error,
        message: error,
      },
    });
  }
};

export const salonUpdateSettings = (params, onUploadProgress) => async (dispatch) => {
  try {
    const result = await salonService.salonUpdateSettings(params, { onUploadProgress });
    const { data } = result;
    if (data.status != 'error') {
      dispatch({
        type: TYPES.ACTION_SALON_UPDATE_SETTINGS_SUCCESS,
        payload: {
          data: data,
        },
      });
    } else {
      dispatch({
        type: TYPES.ACTION_CALL_API_FAIL,
        payload: {
          data: data,
        },
      });
    }
  } catch (error) {
    dispatch({
      type: TYPES.ACTION_CALL_API_FAIL,
      payload: {
        data: error,
        message: error,
      },
    });
  }
};

export const userAction = {
  salonList,
  salonCreate,
  guessSalonGet,
  salonUpdateWorkingTime,
  salonUpdateSettings,
};
