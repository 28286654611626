import _ from 'lodash';
import moment from 'moment';

const BookingStates = {
  open: "open",
  close: "close",
};

const BookingSlotsStates = {
  blank: "blank",
  hasBooking: "hasBooking",
  full: "full",
};
const jsonEscape = (str) => {
  return str.replace(/\n/g, '\\\\n').replace(/\r/g, '\\\\r').replace(/\t/g, '\\\\t');
};

const formatPrice = (price) => {
  // let numberOfDecimalDigits = 3;
  // const format = numberOfDecimalDigits === 0
  //   ? '0,0'
  //   : `0,0.[${Array(numberOfDecimalDigits + 1).join('0')}]`;
  // return price ? numeral(price).format(format) : 0;

  let nf = new Intl.NumberFormat('en-US');
  // nf.format(number); // "1,234,567,890"
  return price ? nf.format(price) : 0;
};

const formatScheduleToDisplay = (schedule) => {
  const dataObj = JSON.parse(schedule);

  if (_.isEmpty(dataObj)) return [];

  const [key, data] = Object.entries(dataObj)[0];
  if (!key || !data) return [];

  const resultArray = [];

  for (const [key, value] of Object.entries(data)) {
    const time = key.replace('_', ':');

    resultArray.push({ time, state: value });
  }

  // Sort the array by time
  resultArray.sort((a, b) => {
    const timeA = a.time;
    const timeB = b.time;

    if (timeA < timeB) return -1;
    if (timeA > timeB) return 1;
    return 0;
  });

  return resultArray;
};

const formatScheduleToSave = (date, data = []) => {
  const result = {};

  for (const item of data) {
    const time = item.time.replace(':', '_');
    result[time] = item.state.booking_state === BookingStates.close ? false : true;
  }

  return {
    date: moment(date).format('YYYY-MM-DD'),
    data: JSON.stringify(result),
  };
};

function isValidJSON(str) {
  try {
    JSON.parse(str);
    return str && JSON.parse(str);
  } catch (e) {
    return false;
  }
}

const getJapaneseDayOfWeek = (day) => {
  const daysOfWeek = ['日', '月', '火', '水', '木', '金', '土'];
  return daysOfWeek[day];
};

const convertToJapaneseTime = (num) => {
  const totalMinutes = num * 30;
  const duration = moment.duration(totalMinutes, 'minutes');
  const hours = duration.hours();
  const minutes = duration.minutes();

  let japaneseTime = '';
  if (hours > 0) {
    japaneseTime += `${hours}時間`;
  }
  if (minutes > 0) {
    japaneseTime += `${minutes}分`;
  }

  return japaneseTime.trim();
};

const getError = ({ ...props }) => {
  const errorCode = _.has(props.actionNotify, 'error_code') ? _.get(props, 'actionNotify.error_code') : null;
  const message = _.has(props.actionNotify, 'message') ? _.get(props, 'actionNotify.message') : null;
  return { errorCode, message };
};

function isNumber(value) {
  return typeof value === 'number' && !isNaN(value);
}

function isStaffLogin() {
  const salonToken = localStorage.getItem('salon_token');
  const isStaff = salonToken && salonToken.length > 0 ? true : false;
  return isStaff;
}



export default {
  jsonEscape,
  formatPrice,
  formatScheduleToDisplay,
  formatScheduleToSave,
  isValidJSON,
  getJapaneseDayOfWeek,
  convertToJapaneseTime,
  getError,
  isNumber,
  isStaffLogin,
  BookingStates,
  BookingSlotsStates
};
