import axios from 'axios';
import _ from 'lodash';
import querystring from 'querystring';

const get = (url, data = {}, headers = null) => {
  const accessToken = window.localStorage.getItem('salon_token');
  if (accessToken) axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;

  return axios
    .get(`${url}${_.isEmpty(data) ? '' : '?' + querystring.stringify(data || {})}`, {
      timeout: 180000,
      headers: {
        'x-booking-ref': localStorage.getItem('booking_ref') || '',
      },
    })
    .catch((error) => {
      if (!error.response) {
        error.response = {
          data: {
            error_code: 102,
            message: 'No response',
          },
        };
      }

      if (_.get(error, 'response.status') == 401) {
        console.log('error', new Date().toLocaleString(), error);
        localStorage.removeItem('salon_token');
        window.location.href = '/login';
      }

      throw error;
    })
    .then((res) => {
      let newToken = _.get(res, ['headers', 'x-auth-token']);
      if (newToken) {
        window.localStorage.setItem('salon_token', newToken);
        console.log('update salon_token', new Date().toLocaleString());
      }
      return res;
    });
};

const post = (url, data = {}, config = null) => {
  const accessToken = window.localStorage.getItem('salon_token');
  if (accessToken) axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;

  return axios
    .post(url, data || {}, {
      ...(config || {}),
      timeout: 1800000,
      headers: {
        'x-booking-ref': localStorage.getItem('booking_ref') || '',
      },
    })
    .catch((error) => {
      if (!error.response) {
        error.response = {
          data: {
            error_code: 102,
            message: 'No response',
          },
        };
      }

      if (_.get(error, 'response.status') == 401) {
        console.log('error', new Date().toLocaleString(), error);

        localStorage.removeItem('salon_token');
        window.location.href = '/login';
      }

      throw error;
    })
    .then((res) => {
      let newToken = _.get(res, ['headers', 'x-auth-token']);
      if (newToken) {
        window.localStorage.setItem('salon_token', newToken);
        console.log('update salon_token', new Date().toLocaleString());
      }
      return res;
    });
};

const postForm = (url, data = {}, config = null) => {
  const accessToken = window.localStorage.getItem('salon_token');
  if (accessToken) axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;

  return axios
    .post(url, data, {
      ...(config || {}),
      timeout: 1800000,
      mimeType: 'multipart/form-data',
      headers: {
        'x-booking-ref': localStorage.getItem('booking_ref') || '',
      },
    })
    .catch((error) => {
      if (!error.response) {
        error.response = {
          data: {
            error_code: 102,
            message: 'No response',
          },
        };
      }

      if (_.get(error, 'response.status') == 401) {
        console.log('error', new Date().toLocaleString(), error);

        localStorage.removeItem('salon_token');
        window.location.href = '/login';
      }

      throw error;
    })
    .then((res) => {
      let newToken = _.get(res, ['headers', 'x-auth-token']);
      if (newToken) {
        window.localStorage.setItem('salon_token', newToken);
        console.log('update salon_token', new Date().toLocaleString());
      }
      return res;
    });
};

const postForm2 = async (url, data = {}, config = null, onProgress = () => {}) => {
  const accessToken = window.localStorage.getItem('salon_token');
  if (accessToken) axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;

  let res;
  try {
    res = await axios
      .post(url, data, {
        ...(config || {}),
        timeout: 1800000,
        mimeType: 'multipart/form-data',
        headers: {
          'x-booking-ref': localStorage.getItem('booking_ref') || '',
        },
        onUploadProgress: (progressEvent_1) => {
          if (onProgress && progressEvent_1.total) {
            const percentageCompleted = Math.round((progressEvent_1.loaded * 100) / progressEvent_1.total);
            onProgress(percentageCompleted);
          }
        },
      });
  } catch (error) {
    if (!error.response) {
      error.response = {
        data: {
          error_code: 102,
          message: 'No response',
        },
      };
    }

    if (_.get(error, 'response.status') == 401) {
      console.log('error', new Date().toLocaleString(), error);

      localStorage.removeItem('salon_token');
      window.location.href = '/login';
    }

    throw error;
    const res = undefined;
  }
  let newToken = _.get(res, ['headers', 'x-auth-token']);
  if (newToken) {
    window.localStorage.setItem('salon_token', newToken);
    console.log('update salon_token', new Date().toLocaleString());
  }
  return res;
};

export const API = { get, post, postForm, postForm2 };
