import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import moment from 'moment';
import { connect } from 'react-redux';
import { TYPES } from '../../../actions';
import { cleanGuessChoice, cancelBooking, guestCheckValidLineUserId, getBookingRefGuest } from '../../../actions';
import { LANGUAGES, getTranslate, notify } from '../../../utils';

class Booking extends Component {
  constructor(props) {
    super(props);

    let params = new URLSearchParams(window.location.search);
    this.state = {
      salon_uuid: params.get('sid') ?? '',
      line_id: params.get('line_user_id') ?? '',
      booking_uuid: params.get('booking_uuid') ?? '',
      booking_ref: '',
    };
  }
  componentDidMount() {
    console.log('this.props: ', this.props);
    if (this.state.line_id) {
      localStorage.removeItem('booking_ref');
      this.props.getBookingRefGuest();
    }
    if (window.location.pathname == '/booking/cancel' && this.state.booking_uuid) {
      let route = `/user/crconfirm?booking_uuid=${this.state.booking_uuid}`;
      if (this.props.history) {
        this.props.history.push(route);
      } else {
        window.location.href = route;
      }
    }
  }

  UNSAFE_componentWillReceiveProps(props) {
    console.log('Booking UNSAFE_componentWillReceiveProps props: ', props);
    let { type } = props.actionNotify;
    switch (type) {
      case TYPES.ACTION_GET_BOOKING_REF_GUEST:
        if (this.state.line_id) {
          if (window.location.pathname == '/booking') {
            this.props.guestCheckValidLineUserId({ line_user_id: this.state.line_id });
          }
        }
        break;
      case TYPES.ACTION_GUEST_LINE_USER_ID_INVALID:
        // let msgError = 'Line user id is not valid';
        // notify(msgError, 'error', 8000);
        this.props.history.push('/booking/booking-invalid-line-user-id');
        break;
      case TYPES.ACTION_GUEST_LINE_USER_ID_VALID:
        this.props.cleanGuessChoice(() => {
          if (this.state.line_id && this.state.salon_uuid) {
            let route = `/user/option?sid=${this.state.salon_uuid}&line_user_id=${this.state.line_id}`;
            if (this.props.history) {
              this.props.history.push(route);
            } else {
              window.location.href = route;
            }
          }
        });

        break;
      default:
        break;
    }
  }

  render() {
    return <></>;
  }
}

function mapStateToProps(state) {
  const { staffReducer } = state;

  return {
    // menuDetail: staffReducer.menuDetail,
    actionNotify: staffReducer.actionNotify,
  };
}

export default withRouter(
  connect(mapStateToProps, {
    cleanGuessChoice,
    cancelBooking,
    guestCheckValidLineUserId,
    getBookingRefGuest,
  })(Booking)
);
