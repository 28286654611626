/* eslint-disable camelcase */
/* eslint-disable import/named */
/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
import _ from 'lodash';
import querystring from 'querystring';
import { API } from './API';
import moment from 'moment';

export const getShift = (date) => {
  const result = API.get(`${process.env.REACT_APP_API_URL}/staff-schedule/get`, { date });

  return result;
};

export const saveShift = (params) => {
  const result = API.post(`${process.env.REACT_APP_API_URL}/staff-schedule/create`, params);
  return result;
};

export const getListServices = (params) => {
  const result = API.get(`${process.env.REACT_APP_API_URL}/service/list`, params);
  return result;
};

export const getService = (params) => {
  const result = API.get(`${process.env.REACT_APP_API_URL}/service/get`, params);
  return result;
};

export const deleteService = (params) => {
  console.log('deleteService params: ', params);
  const result = API.get(`${process.env.REACT_APP_API_URL}/service/delete`, params);
  return result;
};

export const createService = (params, onUploadProgress = () => {}) => {
  console.log('createService params: ', params);
  const result = API.postForm2(`${process.env.REACT_APP_API_URL}/service/create`, params, null, onUploadProgress);
  return result;
};
export const serviceCheckPriority = (params) => {
  const result = API.post(`${process.env.REACT_APP_API_URL}/service/check-priority`, params);
  return result;
};

export const getGuessServiceList = (params) => {
  console.log('getGuessServiceList params: ', params);
  const result = API.get(`${process.env.REACT_APP_API_URL}/guess/service/list`, params);
  return result;
};

export const getListGuessSchedule = (params) => {
  console.log('getListGuessSchedule params: ', params);
  const result = API.get(`${process.env.REACT_APP_API_URL}/guess/schedule/list`, params);
  return result;
};

export const getGuessInfo = (params) => {
  console.log('getGuessInfo params: ', params);
  const result = API.get(`${process.env.REACT_APP_API_URL}/guess/user/get`, params);
  return result;
};
export const guestCheckValidLineUserId = (params) => {
  const result = API.post(`${process.env.REACT_APP_API_URL}/guess/booking/check-valid-line-user-id`, params);
  return result;
};

export const createBooking = (params, isStaffLogin = false) => {
  const route = isStaffLogin ? '/booking/create' : '/guess/booking/create';
  const result = API.post(`${process.env.REACT_APP_API_URL}${route}`, params);
  return result;
};

export const checkBookingAvailable = (params, isStaffLogin = false) => {
  const route = isStaffLogin ? '/booking/check-available' : '/guess/booking/check-available';
  const result = API.post(`${process.env.REACT_APP_API_URL}${route}`, params);
  return result;
};

export const staffList = (params) => {
  const result = API.get(`${process.env.REACT_APP_API_URL}/user/list`, params);
  return result;
};
export const staffCreate = (params) => {
  const result = API.post(`${process.env.REACT_APP_API_URL}/user/create`, params);
  return result;
};

export const staffGet = (params) => {
  const result = API.get(`${process.env.REACT_APP_API_URL}/user/get`, params);
  return result;
};

export const staffChangePassword = (params) => {
  const result = API.post(`${process.env.REACT_APP_API_URL}/user/change-password`, params);
  return result;
};
export const staffForgetPassword = (params) => {
  const result = API.post(`${process.env.REACT_APP_API_URL}/user/forget-password`, params);
  return result;
};
export const staffResetPassword = (params) => {
  const result = API.post(`${process.env.REACT_APP_API_URL}/user/reset-password`, params);
  return result;
};
export const getBookingList = (params) => {
  console.log('getBookingList params: ', params);
  const result = API.get(`${process.env.REACT_APP_API_URL}/booking/list`, params);
  return result;
};

export const getBookingCountForMonth = (params) => {
  const result = API.get(`${process.env.REACT_APP_API_URL}/booking/count-for-month`, params);
  return result;
};

export const cancelBooking = (params, isStaffLogin = false) => {
  const route = '/guess/booking/cancel';
  const currentDate = moment().format('YYYY-MM-DD HH:mm:ss');
  const paramsCopy = _.cloneDeep(params);
  paramsCopy.current_date = currentDate;
  const result = API.get(`${process.env.REACT_APP_API_URL}${route}`, paramsCopy);
  return result;
};
export const cancelBookingForSalon = (params, isStaffLogin = false) => {
  const route = '/booking/cancel-for-salon';
  const currentDate = moment().format('YYYY-MM-DD HH:mm:ss');
  const paramsCopy = _.cloneDeep(params);
  paramsCopy.current_date = currentDate;
  const result = API.get(`${process.env.REACT_APP_API_URL}${route}`, paramsCopy);
  return result;
};

export const getGuessBooking = (params) => {
  const result = API.get(`${process.env.REACT_APP_API_URL}/guess/booking/get`, params);
  return result;
};

export const linkLINEAccount = (params) => {
  const result = API.post(`${process.env.REACT_APP_API_URL}/user/link-line-account`, params);
  return result;
};

export const getBookingRefGuest = () => {
  const result = API.get(`${process.env.REACT_APP_API_URL}/get-booking-ref-guest`);
  return result;
};
export const getBookingRefSalon = () => {
  const result = API.get(`${process.env.REACT_APP_API_URL}/get-booking-ref-salon`);
  return result;
};

export const staffService = {
  getShift,
  saveShift,
  getListServices,
  getService,
  deleteService,
  createService,
  serviceCheckPriority,
  getGuessServiceList,
  getListGuessSchedule,
  getGuessInfo,
  createBooking,
  checkBookingAvailable,
  staffList,
  staffCreate,
  staffGet,
  staffChangePassword,
  staffForgetPassword,
  staffResetPassword,
  getBookingList,
  getBookingCountForMonth,
  getGuessBooking,
  cancelBooking,
  cancelBookingForSalon,
  linkLINEAccount,
  guestCheckValidLineUserId,
  getBookingRefGuest,
  getBookingRefSalon
};
