import React, { Component } from 'react'
import TabBar from '../../components/tabmenu/TabBar'

import InformationConfirm from './InformationConfirm'
import HeaderUser from '../../includes/HeaderUser';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { TYPES } from '../../../actions';
import moment from 'moment';
import _ from 'lodash';
import { createGuessUser, createBooking, getGuessChoice, notifyAction, setGuessChoice } from '../../../actions';
import Func from '../../../utils/Func';
import "../../../styles/components/userConfirm.scss"
class UserConfirm extends Component {
    // state 
    constructor(props) {
        super(props);
        this.state = {
            user: {},
            option: {},
            bookingTime: [''],
            line_id: (new URLSearchParams(window.location.search)).get('line_user_id') ?? "",
            salon_uuid : (new URLSearchParams(window.location.search)).get('sid') ?? "",
        };
    }

    componentDidMount() {
        // console.log("UserConfirm componentDidMount this.props: ", this.props);
        this.props.getGuessChoice();
        // const user = _.get(this.props, 'guessChoicing.user', {});
        // const option = _.get(this.props, 'guessChoicing.option', {});
        // const schedules = _.get(this.props, 'guessChoicing.schedules', []);
        // this.setState({
        //     user: user || {},
        //     option: option || {},
        //     bookingTime: [...schedules] || [],
        // })
        // this.props.setGuessChoice({bookingState: "success"});
    }

    UNSAFE_componentWillReceiveProps(props) {
        console.log("UNSAFE_componentWillReceiveProps props: ",props);
        const type = props.actionNotify.type;
        const {errorCode, message} = Func.getError({...props})
        switch (type) {
            case TYPES.ACTION_CREATE_GUESS_USER_SUCCESSFULLY:
                this.props.createBooking({
                    salon_uuid : this.state.salon_uuid,
                    line_user_id: this.state.line_id,
                    booking_time: _.get(this.state, 'bookingTime[0]', ''),
                    purpose: _.get(this.state, 'user.purpose', ''),
                    total_price: _.get(this.state, 'option.price', 0),
                    coupon_code: null,
                    booking_details: JSON.stringify({
                        service: _.omit(this.state.option, 'priority')
                    }),
                    phone: this.state.user.phone,
                })
                break;
            case TYPES.ACTION_CREATE_GUESS_USER_FAIL:
                break;
            case TYPES.ACTION_CREATE_BOOKING_SUCCESSFULLY:
                this.props.setGuessChoice({bookingState: "success"});
                this.props.history.push('/user/completion');
                break;
            case TYPES.ACTION_CREATE_BOOKING_FAIL:
                break;
            case TYPES.ACTION_GET_GUEST_CHOICE:
                const user = _.get(props, 'actionNotify.guessChoicing.user', {});
                const option = _.get(props, 'actionNotify.guessChoicing.option', {});
                const schedules = _.get(props, 'actionNotify.guessChoicing.schedules', []);
                this.setState({
                    user: user || {},
                    option: option || {},
                    bookingTime: [...schedules] || [],
                });
                break;
            default:
                break;
        }
        this.props.notifyAction({type, error_code: errorCode, message});
    }

    onHandleConfirm = () => {
        this.props.createGuessUser({
            user_name: this.state.user.name,
            name: this.state.user.name,
            phone: this.state.user.phone,
            birthday: moment(this.state.user.birthYear).format("YYYY-MM-DD"),
            gender: this.state.user.gender === 0 ? "male" : this.state.user.gender === 1 ? "female" : "other",
            line_id: this.state.line_id,
            salon_uuid : this.state.salon_uuid
        })
    }

    onHandleBack = () => {
        if(this.state.history){
            this.props.history.goBack();
        }else{
            window.location.href = `/user/inputcustomerInfo?sid=${this.state.salon_uuid}&line_user_id=${this.state.line_id}`;
        }
        
    }

    render() {
        const optionDetail = {
            totalTime: `${this.state.option.estimated_total_time || this.state.option.total_time * 30}分`, 
            option: `${this.state.option.name || ""}`,
            totalPrice: `${this.state.option.price || 0}円`
        };
        const bookingTime  = this.state.bookingTime.length > 0 ? moment(this.state.bookingTime[0]) : "";
        const bookingTimeFormat = {
            appointmentDate: this.state.bookingTime && this.state.bookingTime.length > 0 
                                ? bookingTime.format(`MM月DD日（${this.getJapaneseDayOfWeek(bookingTime.day())}）HH:mm`)
                                : "MM月DD日（D）◯◯:◯◯",
        };

        const userInfo = {
            ...this.state.user,
            gender: this.state.user.gender === 0 ? "男" : this.state.user.gender === 1 ? "女": "その他",
        };
        return (
            <div className='container-fluid' style={{maxWidth:1200}}>
                <HeaderUser/>
                <TabBar tab={4} description="" />
                <div>
                    <p style={{ color: "red" }} className='mt-10 mb-0 font-weight-medium tab-user-description'>まだ予約が完了しておりません。
                    </p>
                    <p className='text-dark mb-30 font-weight-medium tab-user-description'>ご入力内容をご確認いただき、お間違いなければ画面最下部の【予約を確定する】ボタンを押してください。ご入力内容を修正する場合は【戻る】ボタンを押してください。</p>

                </div>
                <InformationConfirm 
                onHandleConfirm={this.onHandleConfirm} 
                onHandleBack={this.onHandleBack}
                optionDetail={optionDetail} 
                bookingTime={bookingTimeFormat} 
                userInfo={userInfo} />
            </div>
        )
    }

    getJapaneseDayOfWeek(day) {
        const daysOfWeek = ["日", "月", "火", "水", "木", "金", "土"];
        return daysOfWeek[day];
    }
}

function mapStateToProps(state) {
    const { staffReducer } = state;
    return {
        actionNotify: staffReducer.actionNotify,
        guessChoicing: staffReducer.guessChoicing
    };
}

export default withRouter(connect(mapStateToProps, {
    createGuessUser,
    createBooking,
    setGuessChoice,
    getGuessChoice,
    notifyAction
})(UserConfirm))