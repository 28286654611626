import React, { Component } from 'react';
import InputForm from '../atoms/inputForm';
import { Link } from 'react-router-dom';

import { connect } from 'react-redux';
import _ from 'lodash';
import { TYPES, getUserInfo, salonUpdateWorkingTime, salonUpdateSettings } from '../../../actions';

import { LANGUAGES, getTranslate, checkValidate, notify } from '../../../utils';

const $ = window.$;

class StoreInforSetting extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isFemaleStore: false,
      userInfo: props.userInfo,

      formMode: 'update',
      uploadPercentComplete: 0,
      form: {
        working_time: '',
        time_booking_before: null,
        time_cancel_before: null,
        max_reservation: null,
        file: null,
        file_path: null,
        image_url: '',
      },
      formIsValid: false,
      msg: {},
      userInfo: null,

      actionNotify: {
        time: +new Date(),
      },
    };
  }

  componentDidMount() {
    this.props.getUserInfo();
  }

  UNSAFE_componentWillReceiveProps(props) {
    console.log('Unsafe component will receive props: ', props);
    if (JSON.stringify(this.state.userInfo) != JSON.stringify(props.userInfo)) {
      this.setState({
        userInfo: props.userInfo,
        form: {
          ...this.state.form,
          working_time: _.get(props, 'userInfo.working_time', ''),
          time_booking_before: _.get(props, 'userInfo.time_booking_before', ''),
          time_cancel_before: _.get(props, 'userInfo.time_cancel_before', ''),
          image_url: _.get(props, 'userInfo.image_url', ''),
          max_reservation: _.get(props, 'userInfo.max_reservation', ''),
        },
        isFemaleStore: _.get(props, 'userInfo.salon_gender', false) === 'female' ? true : false,
      });
    }

    if (!_.isEmpty(props.actionNotify) && props.actionNotify.time > this.state.actionNotify.time) {
      switch (props.actionNotify.type) {
        case TYPES.ACTION_SALON_UPDATE_SETTINGS_SUCCESS:
          this.props.getUserInfo();
          setTimeout(() => {
            notify('店舗情報設定が完了しました。', 'success');
          }, 200);

          this.setState({
            form: {
              ...this.state.form,
              file: null,
              file_path: null,
            },
            uploadPercentComplete: 0
          })

          break;

        default:
          break;
      }
      this.setState({
        actionNotify: props.actionNotify,
      });
    }
  }

  onChangeFormField = (e, field) => {
    const value = e.target.value || '';
    let isFileField = field == 'file';
    let isClearFile = field == 'clear_file';
    let newForm = {
      ...this.state.form,
      [field]: isFileField ? _.get(e, 'target.files[0]') : value.trim(),
    };
    if (isFileField) {
      newForm.file_path = value;
    }
    if (isClearFile) {
      delete newForm[field];
      newForm = {
        ...newForm,
        file: null,
        file_path: null,
        image_url: '',
      };
    }

    console.log('onChangeFormField', field, value);

    this.setState({
      form: newForm,
      msg: {},
      uploadPercentComplete: 0,
    });

    document.getElementById('txtWorkingTime').setCustomValidity('');
    document.getElementById('txtTimeBookingBefore').setCustomValidity('');
    document.getElementById('txtTimeCancelBefore').setCustomValidity('');
    document.getElementById('txtMaxReservation').setCustomValidity('');

    if (this.form) this.form.classList.remove('was-validated');
  };

  handleSubmit = async (e) => {
    e.preventDefault();

    const form = e.currentTarget;
    this.form = form;

    let msg = {};
    if (!/\b\d{1,2}h\d{2}-\d{1,2}h\d{2}\b/.test(this.state.form.working_time)) {
      msg.working_time = getTranslate(LANGUAGES.TEXT_PLEASE_ENTER_WORKING_TIME_WITH_FORMAT_START_END);
    }

    if (!(/^[0-9]\d{0,2}$/.test(this.state.form.time_booking_before) && +this.state.form.time_booking_before <= 720)) {
      msg.time_booking_before = '予約制限時間を正しく入力してください';
    }

    if (!(/^[0-9]\d{0,2}$/.test(this.state.form.time_cancel_before) && +this.state.form.time_cancel_before <= 720)) {
      msg.time_cancel_before = 'キャンセル制限時間を正しく入力してください';
    }

    if (!/^[0-9]\d{0,2}$/.test(this.state.form.max_reservation)) {
      msg.max_reservation = '目安時間を正しく入力してください';
    }

    let formIsValid = _.isEmpty(msg);

    this.setState({
      msg,
      formIsValid,
    });

    console.log('msg', msg);

    document.getElementById('txtWorkingTime').setCustomValidity(_.get(msg, 'working_time', ''));
    document.getElementById('txtTimeBookingBefore').setCustomValidity(_.get(msg, 'time_booking_before', ''));
    document.getElementById('txtTimeCancelBefore').setCustomValidity(_.get(msg, 'time_cancel_before', ''));
    document.getElementById('txtMaxReservation').setCustomValidity(_.get(msg, 'max_reservation', ''));

    if (form.checkValidity() === false || !formIsValid) {
      e.preventDefault();
      e.stopPropagation();
      console.log('form is invalid');
    } else {
      console.log('form is valid');
      // this.props.salonUpdateWorkingTime({
      //   working_time: this.state.form.working_time
      // });

      const formData = new FormData();
      formData.append('file', this.state.form.file);
      formData.append('image_url', this.state.form.image_url);
      formData.append('gender', this.state.isFemaleStore ? 'female' : 'others');
      formData.append('working_time', this.state.form.working_time);
      formData.append('time_booking_before', this.state.form.time_booking_before === '' ? null : this.state.form.time_booking_before);
      formData.append('time_cancel_before', this.state.form.time_cancel_before === '' ? null : this.state.form.time_cancel_before);
      formData.append('max_reservation', this.state.form.max_reservation === '' ? null : this.state.form.max_reservation);
      this.props.salonUpdateSettings(formData, (progressEvent) => {
        const percentCompleted = Math.round((progressEvent.loaded / progressEvent.total) * 100);
        this.setState({
          uploadPercentComplete: Math.max(percentCompleted - 5, 1),
        });
        console.log('percentCompleted', percentCompleted);
      });
    }

    form.classList.add('was-validated');
  };

  onUploadProgress = () => {};

  handleCheckboxChange = (e) => {
    this.setState({
      isFemaleStore: e.target.checked,
    });
  };

  validateFileSize = (e, field) => {
    let input = e.currentTarget;
    const maxSize = 20 * 1024 * 1024; // 20MB in bytes
    const file = input.files[0];

    if (file && file.size > maxSize) {
      notify('ファイルサイズが20MBを超えています。\n20MB以下のファイルを選択してください。', 'error');
      input.value = ''; // Clear the input

      return;
    }

    this.onChangeFormField(e, field);
  };

  render() {
    const { error, isFemaleStore } = this.state;

    setInterval(() => {
      let $bar = $('.progress-bar');
      $bar.width(($bar.parent().width() * this.state.uploadPercentComplete) / 100 + 'px');
    }, 40);

    return (
      <div className="text-center">
        <h1 style={{ color: 'black', fontSize: '26px', fontWeight: 'bold', marginBottom: '20px' }}>店舗情報設定</h1>
        <h2
          style={{
            color: 'black',
            fontSize: '16px',
            marginBottom: '20px',
            fontWeight: 'bold',
            width: '90%',
            maxWidth: 500,
            textAlign: 'left',
            margin: 'auto',
            paddingBottom: '10px',
          }}
        >
          <span style={{ fontSize: '20px' }}>店舗情報設定</span>
          <br />
          営業時間を開始時間-終了時間のフォーマットで
          <br />
          入力してください（例:8h00-23h00）
        </h2>

        <div className="d-flex justify-content-center">
          <form noValidate autoComplete="off" className={'needs-validation'} onSubmit={this.handleSubmit} style={{ width: '90%', maxWidth: 500 }}>
            <div className="mx-auto" style={{ width: '100%' }}>
              <InputForm
                style={{ width: '100%' }}
                type={'input'}
                required="true"
                id="txtWorkingTime"
                msg={_.get(this.state, 'msg.working_time', '')}
                value={this.state.form.working_time}
                onChange={(e) => this.onChangeFormField(e, 'working_time')}
              />

              <label style={{ float: 'left', color: 'black', fontSize: '16px', fontWeight: 'bold' }}>枠数</label>
              <InputForm
                style={{ width: '100%' }}
                type={'input'}
                required="true"
                id="txtMaxReservation"
                msg={_.get(this.state, 'msg.max_reservation', '')}
                value={this.state.form.max_reservation}
                onChange={(e) => this.onChangeFormField(e, 'max_reservation')}
              />

              <label style={{ float: 'left', color: 'black', fontSize: '16px', fontWeight: 'bold' }}>予約制限時間(時間) (0～720時間)</label>
              <InputForm
                style={{ width: '100%' }}
                type={'input'}
                required="true"
                id="txtTimeBookingBefore"
                msg={_.get(this.state, 'msg.time_booking_before', '')}
                value={this.state.form.time_booking_before}
                onChange={(e) => this.onChangeFormField(e, 'time_booking_before')}
              />

              <label style={{ float: 'left', color: 'black', fontSize: '16px', fontWeight: 'bold' }}>キャンセル制限時間 (時間) (0～720時間)</label>
              <InputForm
                style={{ width: '100%' }}
                type={'input'}
                required="true"
                id="txtTimeCancelBefore"
                msg={_.get(this.state, 'msg.time_cancel_before', '')}
                value={this.state.form.time_cancel_before}
                onChange={(e) => this.onChangeFormField(e, 'time_cancel_before')}
              />
              {/* <label style={{ float: 'left', color: 'black', fontSize: '16px' }}>Salon image</label> */}
              <div className="d-flex flex-row text-center">
                <p style={{ color: 'black', fontSize: '16px', float: 'left', fontWeight: 'bold' }}>画像</p>
                {/* <span style={{ fontSize: '16px' }} className="span-req req">
                  必須
                </span> */}
              </div>
              {!this.state.form.image_url && (
                <div style={{ padding: '0.3rem', border: '1px solid #ced4da', display: 'flex', borderRadius: '0.25rem', justifyContent: 'space-between', alignItems: 'center' }}>
                  <p style={{ margin: '0px', textAlign: 'left', color: 'black' }}>{this.state.form.file_path || 'ファイルを選択されていません'}</p>
                  <label
                    for="fileInput"
                    style={{
                      color: 'black',
                      margin: '0',
                      padding: '10px',
                      border: '1px solid #ced4da',
                      borderRadius: '0.25rem',
                      fontSize: '12px',
                      background: 'rgba(211, 211, 211, 0.3)',
                      cursor: 'pointer',
                      userSelect: 'none',
                    }}
                  >
                    ファイルを選択
                  </label>
                  <input type="file" id="fileInput" accept="image/*" onChange={(e) => this.validateFileSize(e, 'file')} style={{ width: '7rem' }} />
                </div>
              )}

              {this.state.form.file && !!this.state.uploadPercentComplete && (
                <div className="mb-10 mt-10" style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                  <div className="progress" style={{ width: 'calc(100% - 2.5rem)', height: '0.7rem', borderRadius: '1rem' }}>
                    <div className="progress-bar bar" role="progressbar" aria-valuenow={this.state.uploadPercentComplete} aria-valuemin="0" aria-valuemax="100" style={{ borderRadius: '1rem' }}></div>
                  </div>
                  <div style={{ color: 'black', fontSize: '14px' }}>{this.state.uploadPercentComplete}%</div>
                </div>
              )}
              {this.state.form.image_url && (
                <div style={{ position: 'relative', display: 'flex', justifyContent: 'left', alignItems: 'start' }}>
                  <img src={this.state.form.image_url} style={{ height: '100px' }} />
                  <button type="button" style={{ background: 'transparent', border: 'none', marginLeft: '-15px', marginTop: '-20px' }} onClick={(e) => this.onChangeFormField(e, 'clear_file')}>
                    <i class="fa fa-times-circle" aria-hidden="true" style={{ fontSize: '26px' }}></i>
                  </button>
                </div>
              )}

              {error && <div style={{ color: 'red' }}>{error}</div>}
              <div style={{ display: 'flex', alignItems: 'center', marginBottom: '20px', marginTop: '20px ' }}>
                <input
                  style={{
                    // appearance: 'none',
                    width: '20px',
                    height: '20px',
                    marginRight: '8px',
                    cursor: 'pointer',
                    // border: '2px solid black',
                    // backgroundColor: isChecked ? 'blue' : 'white',
                  }}
                  type="checkbox"
                  checked={isFemaleStore}
                  onChange={this.handleCheckboxChange}
                />
                <span
                  style={{
                    color: 'black',
                    cursor: 'pointer',
                    fontSize: '16px',
                    fontWeight: 'bold',
                    userSelect: 'none',
                  }}
                  onClick={() => this.setState({ isFemaleStore: !isFemaleStore })}
                >
                  女性専用サロンとする
                </span>
              </div>
              <button style={{ width: '100%', fontWeight: 'bold', fontSize: '22px' }} type="submit" className="btn btn-success btn-block btn-round">
                設定
              </button>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { staffReducer, userReducer } = state;

  return {
    actionNotify: staffReducer.actionNotify,
    userInfo: userReducer.userInfo,
  };
}

export default connect(mapStateToProps, {
  getUserInfo,
  salonUpdateWorkingTime,
  salonUpdateSettings,
})(StoreInforSetting);
